import { useEffect, useState, useCallback } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BreadcrumbList, Header, PrimaryButton, SiteModal, Tabs, TextBlock } from "../../../components/common";
import { constants, Utils } from "../../../helpers";
import PageWrapper from "../../PageWrapper";
import { HubInfoBlock, HubNetworkBlock } from "./blocks";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceInformation, setDeviceInformation, getAllMqttDataFromResponse, resetAllMqttDataFromResponse, getAccountId } from "../../../store/reducers/AccountReducer";
import { setCDNInfo, setStartDate, getPlatformInfo } from "../../../store/reducers/StreamingReducer";
import { HiOutlineInformationCircle } from "react-icons/hi";
import axios from "axios";
import { useLoggedInUserData } from "../../../store/LoggedInAccountStore";
import { getCurrentEventResource } from "../../../utils/connection/mqttConnection";
import { TbCloudLock } from "react-icons/tb";
import "./ChannelDetails.scss";
import useLicensesStore from "../../../store/LicensesStore";
import { HiExclamationCircle } from "react-icons/hi2";
import { ReactComponent as InfoCircleIcon } from "../../../assets/images/Info-circle.svg";
import { RxCross1 } from "react-icons/rx";
import { devicesMQTTStore } from "../../../store/DevicesMQTTStore";
import arrowLeft from "../../../assets/images/icons/Arrowleft.svg";
import { DefaultDevice } from "../../../assets/images";
import {
  getAllDevicesData,
  // getAllMqttDataFromResponse,
  getSelectedOrganization,
  // resetAllMqttDataFromResponse,
  setAllDevicesData,
} from "../../../store/AccountStoreIDB";
import { getOrgInfo } from "../../../store/reducers/OrganizationsReducer";


const ChannelDetails = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const orgInfo = useSelector(getOrgInfo)
	const isRedirectToNetwork = location?.state?.isRedirectToNetwork || false;
	const allMqttData = useSelector(getAllMqttDataFromResponse);
	const accountId = useSelector(getAccountId);
	let [searchParams] = useSearchParams();
	const deviceId = searchParams.get("deviceId");
	// const orgDetails = useSelector(getSelectedOrganization);
	const [orgDetails, setOrgDetails] = useState();
	const deviceDetails = useSelector(getDeviceInformation);
	// const devicesDetails = useSelector(getAllDevicesData);
	const [devicesDetails, setDevicesDetails] = useState([]);
	const dispatch = useDispatch();
	const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);
	const [activeSection, setActiveSection] = useState(0);
	const [saveTabSettingsDataFlag, setSaveTabSettingsDataFlag] = useState(false);
	const [canAttachLicense, setCanAttachLicense] = useState(deviceDetails?.claimed);
	const [moveLocationData, setMoveLocationData] = useState({});
	const [showDeviceRemoveModal, setShowDeviceRemoveModal] = useState(false);
	const [userMsg, setUserMsg] = useState("");
	const platformDetails = useSelector(getPlatformInfo);
	const { setLicenses } = useLicensesStore();
	const publishTopic = platformDetails?.mqtt?.topic_details?.publish?.settings;
	const { getState } = devicesMQTTStore;
	const state = getState();
	const sessionId = state.getSessionId();
	const [deviceInfo, setDeviceInfo] = useState(null);
	const [isReload, setIsReload] = useState(false);
    const deviceState = deviceInfo?.deviceStatus;
    const connectionState = deviceInfo?.connectionStatus;
    const getLoggedInUserUUID = useLoggedInUserData((state) => state.getLoggedInUserUUID);
	const devicePublishId = Utils.replaceStringValues(publishTopic?.[0], "${deviceId}", `${deviceDetails?.gatewayId}`);
	const [removeButtonDisableFlag, setRemoveButtonDisableFlag] = useState(true);

	const breadList = [
		{
            url: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)?.url,
            title: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)?.title,
        },
		{
			url: `/landing-page.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}&fromPush=true`,
			title: orgInfo?.orgName ? `${orgInfo?.orgName}` : "",
		},
		{
			url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
			title: constants.DEVICES_PAGE_TITLE,
		},
		{
			url: "/devices/channel-details.html?deviceId",
			title: `${deviceDetails?.deviceName}`,
		},
	];
	const mobileBreadList=[
        {
			url: `/devices/listing.html?orgId=${orgDetails?.orgId}`,
			title: constants.DEVICES_PAGE_TITLE,
       },
       
   ]

   	useEffect(() => {
  	   	const loadSelectedOrgData = async () => {
       		const org = await getSelectedOrganization();
       		setOrgDetails(org || {});
     	};
     	loadSelectedOrgData();
   }, []);

	useEffect(() => {
		const fetchDevices = async () => {
		  const allDevices = await getAllDevicesData();
		  setDevicesDetails(allDevices);
		};
		fetchDevices();
	}, []);

	useEffect(() => {
		if (orgDetails?.orgId) {
			getLicensesCall();
		}
		if (isRedirectToNetwork) {
			setSectionBlock(1);
		}
	}, [orgDetails?.orgId]);

	useEffect(() => {
		if (deviceId && orgDetails?.orgId ) {
			fetchDeviceData();
		}
	}, [deviceId, isReload, orgDetails?.orgId ]);

	const fetchDeviceData = async () => {
		axios.get(`device/orgs/${orgDetails?.orgId}/devices/${deviceId}/v2`, Utils.requestHeader()).then((response) => {
			const resultData = response.data;
			if (resultData) {
				const responseMeta = resultData.meta;
				const responseData = resultData.data;
				
				let responseAppValue = responseData?.apps;
				if (!responseAppValue || Object.keys(responseAppValue).length === 0) {
					setRemoveButtonDisableFlag(false);
				}else {
					let deviceAppsAnyValueTrue =  Object.values(responseAppValue).some(value => value === true);
					if(deviceAppsAnyValueTrue){
						setRemoveButtonDisableFlag(true);
					}else{
						setRemoveButtonDisableFlag(false);
					}
				}

				if (responseMeta && (responseMeta.code === 200 || responseMeta.code === "200")) {
					getCapabiltiesForDevice(responseData);
					// setDeviceInfo(responseData);
				}
			}
		});
	};

	const getCapabiltiesForDevice = async (deviceData) => {
		if (deviceData?.capability) {
		  try {
			let response = await fetch(deviceData?.capability?.url);
			if (response.status === 200) {
			  let responseJson = await response.json();
			  deviceData.capDetails = responseJson;
			  setDeviceInfo(deviceData);
			  if (deviceDetails?.deviceId === deviceId) {
				dispatch(setDeviceInformation(deviceData));
			  }
			} else {
			  setDeviceInfo(deviceData);
			}
		  } catch (error) {
			setDeviceInfo(deviceData);
		  }
		} else {
		  setDeviceInfo(deviceData);
		  if (deviceDetails?.deviceId === deviceId) {
            dispatch(setDeviceInformation(deviceData));
          }
		}
	  };

	const getLicensesCall = async () => {
		await setLicenses(`/user/orgs/${orgDetails?.orgId}/licenses`);
	};

	useEffect(() => {
		if (getCurrentEventResource(allMqttData?.msg?.resource) && deviceId === allMqttData?.from) {
			updateDeviceStore(allMqttData?.msg?.properties, true, allMqttData?.msg?.action !== "error");
		}
	}, [allMqttData]);

	const updateDeviceStore = async (properties, isFromMqtt, mqttStatus) => {
		let openDeviceDetails = JSON.parse(JSON.stringify(deviceDetails));
		if (openDeviceDetails && openDeviceDetails?.deviceId === deviceId) {
		let deficeInfoProperty = openDeviceDetails?.properties;
		mqttStatus &&
			localStorage.setItem(
			"localStoreProperty",
			JSON.stringify({ ...deficeInfoProperty })
			);
		let localStoreProperty = JSON.parse(
			localStorage.getItem("localStoreProperty")
		);
		deficeInfoProperty = mqttStatus
			? { ...deficeInfoProperty, ...properties }
			: localStoreProperty && {
				...deficeInfoProperty,
				...localStoreProperty,
			};
		openDeviceDetails = {
			...openDeviceDetails,
			properties: deficeInfoProperty,
		};
		if (deviceDetails?.deviceId === deviceId && deviceDetails?.capDetails) {
			openDeviceDetails = {
			...openDeviceDetails,
			capDetails: deviceDetails?.capDetails,
			};
		}
		let updatedList = devicesDetails?.map((alldevice, index) => {
			if (alldevice.deviceId === openDeviceDetails.deviceId) {
			return {
				...openDeviceDetails,
			};
			} else {
			return { ...alldevice };
			}
		});
		let dList = await Promise.all(updatedList);
		await setAllDevicesData(dList);
		setDeviceInfo(openDeviceDetails);
		dispatch(setDeviceInformation(openDeviceDetails));
		isFromMqtt && dispatch(resetAllMqttDataFromResponse());
		} else {
		Utils.vmsLogger().log(
			"Device with received mqtt message deviceId, is not listed."
		);
		}
	};

	useEffect(() => {
		if (orgDetails?.orgId) {
			axios
				.get(`/partner/orgs/${orgDetails?.orgId}/cdn`, {
					withCredentials: true,
					...Utils.requestHeader(),
				})
				.then((response) => {
					if (response?.data?.data) {
						dispatch(setCDNInfo(response?.data?.data?.cdn));
						// TODO: Delete later
						console.log("signed cookkie updated");
					}
				});
		}
	}, [orgDetails?.orgId]);

	useEffect(() => {
		if (deviceDetails) {
			const url = deviceDetails?.entitlement?.url;
			if (url) {
				fetchData(url);
			}
		}
	}, [deviceDetails]);

	useEffect(() => {
		if(deviceInfo){
		  updateConnectionStatus();
		}
	}, [devicesDetails]);
	
	const updateConnectionStatus = () => {
		const deviceD = JSON.parse(JSON.stringify(deviceInfo));
		let isUpdated = false;
		const statusDevice = devicesDetails?.find(
			(deviceL) => deviceL.deviceId === deviceD.deviceId
		);
		if (
			statusDevice &&
			statusDevice.deviceStatus &&
			statusDevice.deviceStatus !== deviceD.deviceStatus
		) {
			isUpdated = true;
			deviceD['deviceStatus'] = statusDevice.deviceStatus;
			deviceD['displayDeviceStatus'] = Utils.getDeviceStatus(
			statusDevice.deviceStatus,
			deviceD.connectionStatus
			);
		}
		if (
			statusDevice &&
			statusDevice.connectionStatus &&
			statusDevice.connectionStatus !== deviceD.connectionStatus
		) {
			isUpdated = true;
			deviceD['connectionStatus'] = statusDevice.connectionStatus;
			deviceD['displayDeviceStatus'] = Utils.getDeviceStatus(
			deviceD.deviceStatus,
			statusDevice.connectionStatus
			);
		}
		deviceD?.channels &&
			deviceD?.channels?.forEach((child) => {
			const childStatusDevice = devicesDetails?.find(
				(deviceL) => deviceL.deviceId === child.deviceId
			);
			if (
				childStatusDevice &&
				childStatusDevice.deviceStatus &&
				childStatusDevice.deviceStatus !== child.deviceStatus
			) {
				isUpdated = true;
				child['deviceStatus'] = childStatusDevice.deviceStatus;
				child['displayDeviceStatus'] = Utils.getDeviceStatus(
				childStatusDevice.deviceStatus,
				child.connectionStatus
				);
			}
			if (
				childStatusDevice &&
				childStatusDevice.connectionStatus &&
				childStatusDevice.connectionStatus !== child.connectionStatus
			) {
				isUpdated = true;
				child['connectionStatus'] = childStatusDevice.connectionStatus;
				child['displayDeviceStatus'] = Utils.getDeviceStatus(
				child.deviceStatus,
				childStatusDevice.connectionStatus
				);
			}
			});
		if (isUpdated) {
			setDeviceInfo(deviceD);
		}
	};

	const fetchData = async (url) => {
		fetch(url, {
			withCredentials: true,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data) {
					const expiryDate = data?.term?.expiry;
					const durationInDays = data?.term?.data?.durationInDays;
					const startDate = expiryDate - Utils.daysToMilliseconds(durationInDays);
					dispatch(setStartDate(startDate));
				}
			});
	};

	const setSectionBlock = (index) => {
		setIsReload(!isReload);
		setSaveTabSettingsDataFlag(false);
		setActiveSection(index);
	};

	const addLicenseButtonHandler = () => {
		setShowAddLicenseModal(true);
	};

	const saveDeviceInfoSettingsButtonHandler = () => {
		setSaveTabSettingsDataFlag(true);
	};
	const saveDeviceMoveLocationAreaData = (locationData) => {
		setMoveLocationData(locationData);
	};
	const updateStoreDeviceSetting = (properties) => {
		updateDeviceStore(properties, false, true);
	};
	const moveDeviceLocation = async () => {
		try {
			const orgID = deviceDetails?.orgId;
			const locationId = moveLocationData?.oldLocationId ? moveLocationData?.oldLocationId : deviceDetails?.locationId;
			const reqBody = {
				newLocationId: moveLocationData?.newLocationId ? moveLocationData?.newLocationId : deviceDetails?.locationId,
				deviceName: moveLocationData?.deviceName ? moveLocationData?.deviceName : deviceDetails?.deviceName,
			};
			const res = await axios.put(`/device/orgs/${orgID}/locations/${locationId}/devices/${deviceId}`, reqBody, Utils.requestHeader());
			if (res?.data?.meta?.code === 200) {
				const data = res?.data?.data;
				const updatedDeviceInfo = {
					...deviceInfo, 
					deviceName: data?.deviceName,
					locationId: data?.locationId, 
					areaId: data?.areaId
				};
				setDeviceInfo(updatedDeviceInfo);
				await axios.get(`device/orgs/${orgID}/devices`, Utils.requestHeader()).then((response) => {
					let resultData = response.data;
					if (resultData) {
						let responseMeta = resultData.meta;
						let responseData = resultData.data;
						if (parseInt(responseMeta?.code) === 200) {
							getCapabiltiesFromDeviceList(responseData);
						}
					}
				});
			} else {
				console.error("ERROR: ", res?.data);
			}
		} catch (error) {
			console.error("ERROR: ", error);
		}
	};

	const getCapabiltiesFromDeviceList = async (deviceData) => {
		let deviceList = [...deviceData];
		let newDeviceList = deviceList?.map(async (device, index) => {
			const deviceInfo = devicesDetails?.find((deviceStore) => deviceStore?.deviceId === device?.deviceId);
			// let isCapabilityCall = devicesDetails?.length === 0 || deviceInfo?.capDetails === undefined || deviceInfo?.capability?.version !== device?.capability?.version;
			// if (device?.capability && isCapabilityCall) {
			// 	let response = await fetch(device.capability.url);
			// 	if (response.status === 200) {
			// 	let responseJson = await response.json();
			// 	return { ...device, capDetails: responseJson };
			// 	} else {
			// 	return { ...device };
			// 	}
			// } else 
			if (
				device?.capability &&
				deviceInfo?.capability?.version === device?.capability?.version
			) {
				return { ...device, capDetails: deviceInfo?.capDetails };
			} else {
				return { ...device };
			}
		});
		let deviceCapability = await Promise.all(newDeviceList);
		if (deviceCapability?.length) {
			const currentDeviceDetail = deviceCapability?.find((device) => device.deviceId === deviceId);
			dispatch(setDeviceInformation(currentDeviceDetail));
		}
		await setAllDevicesData(deviceCapability);
	};

	// sectionBlock and buttonGroup must have the same number of keys.
	// Ensure entries in buttonGroup relate to keys in sectionBlock
	const sectionBlock = {
		0: (
			<HubInfoBlock
				orgId={orgDetails?.orgId}
				orgName={orgDetails?.orgName}
				deviceId={deviceId}
				deviceInfos={deviceInfo}
				deviceDetails={deviceDetails}
				saveDeviceSettingsFlag={saveTabSettingsDataFlag}
				accountId={accountId}
				uuid={getLoggedInUserUUID()}
				sessionId={sessionId}
				devicePublishId={devicePublishId}
				saveDeviceSettingsCallback={(value) => {
					saveDeviceInfoSettingsButtonHandler(value);
				}}
				moveDeviceDetailCallback={(locationData) => {
					saveDeviceMoveLocationAreaData(locationData);
				}}
				updateStore={(publishData) => {
					setIsReload(!isReload);
					updateStoreDeviceSetting(publishData);
				}}
				updateFirmware={() => {
					setSectionBlock(1);
				}}
			/>
		),
		1: (
			<HubNetworkBlock
				orgId={orgDetails?.orgId}
				deviceDetails={deviceDetails}
				orgName={orgDetails?.orgName}
				deviceId={deviceId}
				deviceInfos={deviceInfo}
				accountId={accountId}
				sessionId={sessionId}
				devicePublishId={devicePublishId}
				updateStore={(publishData) => {
					setIsReload(!isReload);
					updateStoreDeviceSetting(publishData);
				}}
			/>
		),
	};

	const deleteDeviceCall = async () => {
		try {
			const res = await axios.delete(`/device/orgs/${orgDetails.orgId}/devices/${deviceId}/v2`, Utils.requestHeader());
			if (parseInt(res?.status) === 202 || parseInt(res?.data?.meta?.code) === 200) {
				hideDeviceRemoveModel();
				navigate(`/devices/listing.html?orgId=${orgDetails?.orgId}`);
			} else {
				setUserMsg(res?.meta?.userMsg || res?.data?.meta?.userMsg);
			}
		} catch (error) {
			// TODO : to print the error will update this later
			console.error(error);
		}
	};

	const onDeleteDevice = () => {
		setShowDeviceRemoveModal(true);
	};

	const hideDeviceRemoveModel = () => {
		setShowDeviceRemoveModal(false);
		setUserMsg("");
	};

	const buttonGroup = {
    0: (
      <>
        {/* <PrimaryButton
          className="removeMobileview"
          type="button"
          width="128px"
          fontSize="14px"
          height="44px"
          lineHeight="16px"
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--error_64")}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--error_64")}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            "--error_64"
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--brand_white")}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue("--error_64")}
          backgroundColor="transparent"
          disabled={
            deviceState === constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
            removeButtonDisableFlag === true
          }
          onClick={onDeleteDevice}
        >
          {constants.DEVICES_REMOVE_DEVICE}
        </PrimaryButton> */}
        <PrimaryButton
          type="button"
          width="150px"
          className="device-btn-space btn-primary"
          disabled={
            connectionState !== constants.DEVICES_ONLINE_CONNECTION_STATUS ||
            deviceState === constants.DEVICES_DEACTIVATED_DEVICE_STATUS
          }
          onClick={() => moveDeviceLocation()}
        >
          {constants.DEVICES_SAVE_CHANGES_BUTTON}
        </PrimaryButton>
      </>
    ),
    4: canAttachLicense ? (
      <PrimaryButton
        type="button"
        width="165px"
        className="device-btn-space btn-primary"
        onClick={() => addLicenseButtonHandler()}
      >
        {constants.DEVICES_TAB_LICENSES_ATTACH_NEW_LICENSE_LABEL}
      </PrimaryButton>
    ) : (
      <Button
        variant="primary"
        size="sm"
        className="attach-license-btn disabled"
      >
        {constants.DEVICES_TAB_LICENSES_ATTACH_NEW_LICENSE_LABEL}
      </Button>
    ),
  };

	const tabs = [
		{
			icon: <HiOutlineInformationCircle size={22} />,
			label: constants.DEVICES_TAB_DEVICE_INFO,
			clickHandler: (index) => setSectionBlock(index),
		},
		{
			icon: <TbCloudLock size={22} />,
			label: constants.DEVICES_TAB_DEVICE_PASSWORD,
			clickHandler: (index) => setSectionBlock(index),
		},
	];

	return (
		<div className="App">
			<Header currentDeviceId={deviceId} className="desktop" />
			<div className="breadcrumbsResponsive mobile w-100">
              <div className="text-start me-auto navbar-brand arrow">
                <img src={arrowLeft} alt=""></img>
                <spam
                  className="backText"
                   onClick={() => navigate(mobileBreadList[0]?.url)}

                >
                {constants.BREADCRUMB_BACK}
               
                </spam>
              </div>
            </div>
			<PageWrapper className="mw-100">
				<div className="page-header mb-3">
					<Container className="container-top-offset mw-100">
					    <Col md={6} lg={6} xl={6} xs={12}>
							    <BreadcrumbList list={Utils.CheckBreadcrumbForUserRole(breadList)} />
					 
						</Col>
						<Row>
							<Col md={6} lg={6} xl={6} xs={12} className="page-title text-start parent-device-name">
								<div className='device-name'>
									{deviceDetails?.deviceName}
								</div>

								<p className="page-title-note">{orgDetails?.orgName}</p>
							</Col>
							<Col md={6} lg={6} xl={6} xs={12} className="d-none d-md-flex align-items-md-center justify-content-center justify-content-md-end">
								{buttonGroup[activeSection]}
							</Col>
						</Row>
						<Row className="channel-details device-tabs settingsTabs">
							<Col>
								<Tabs defaultActive={activeSection} tabCollection={tabs} />
							</Col>
						</Row>
						<Row className="channel-content">
							<Col md={6} lg={6} xl={6} xs={12}>
								<div className="channel-icon-container">
										<img src={deviceDetails?.imageURL} onError={(e) => e.target.src = `${DefaultDevice}`} alt="device_image" className="device-icon" />
								</div>
							</Col>
							<Col md={6} lg={6} xl={6} xs={12} className="mt-3 mt-md-0">
								<div className={`details-section-container${activeSection !== 0 && activeSection !== 5 ? " inner-view" : ""}`}>{sectionBlock[activeSection]}</div>
							</Col>
							{/* For Mobile View Button */}
							<Col md={12} className="d-flex d-md-none mt-4 detalsSecButton">
								{buttonGroup[activeSection]}
							</Col>
						</Row>
					</Container>
				</div>
				{/* Want to remove device - modal  */}
				<SiteModal modalTitle={constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_TITLE} showModal={showDeviceRemoveModal} hideModal={hideDeviceRemoveModel} classes="device-settings-restart-required">
					{userMsg && userMsg.length > 0 && (
						<div className="error-container">
							<div className="info-icon-container">
								<InfoCircleIcon />
							</div>
							<div className="error-body">
								<div className="error-content">{userMsg}</div>
								<div className="cross-icon-container">
									<RxCross1 onClick={() => setUserMsg("")} />
								</div>
							</div>
						</div>
					)}
					<TextBlock className="mb-3" fontWeight="400" fontSize="1rem" lineHeight="24px" color={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_56")}>
						{constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_CONTENT}
					</TextBlock>

					<>
						<div className="text-center">
							<HiExclamationCircle className="mb-4 icon-warning-color" size={96} />
						</div>
						<PrimaryButton className="btn btn-primary-outline mb-4" type="button" width="100%" borderWidth="1.5px" hoverBorderWidth="1.5px" hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} color={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--brand_white")} hoverBackgroundColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} backgroundColor="transparent" height="56px" fontSize="1.125rem" lineHeight="24px" onClick={hideDeviceRemoveModel}>
							{constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE}
						</PrimaryButton>
						<PrimaryButton
							className="btn btn-primary"
							type="button"
							width="100%"
							height="56px"
							fontSize="1.125rem"
							lineHeight="24px"
							backgroundColor="transparent"
							hoverBackgroundColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
							borderColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
							hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
							borderWidth="1.5px"
							color={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
							hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--brand_white")}
							onClick={() => {
								deleteDeviceCall();
							}}>
							{constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_MODAL_BUTTON_TITLE}
						</PrimaryButton>
					</>
				</SiteModal>
			</PageWrapper>
		</div>
	);
};

export default ChannelDetails;
