import { useEffect, useMemo, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useKeycloak } from '@react-keycloak/web';
import {
  Avatar,
  Header,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
} from '../../components/common';
import PageWrapper from '../PageWrapper';
import { constants, roles } from '../../helpers';
import { UsersListTable } from '../partnerUsers';
import { AssignCustomers, EditPartnerUsers, NewPartnerUsers } from '.';
import { usePoliciesStore } from '../../store/policiesStore';
import { usePartnerOrgUsers } from '../../store/PartnerOrgUsersStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';

import { FaCircle } from 'react-icons/fa';
import { success } from '../../assets/images';
import { getPartnerOrgData } from '../../store/reducers/OrganizationsReducer';
import { useSelector } from 'react-redux';

const ManagePartnerUsers = () => {
  //=== Store get/set actions
  const partnerOrgData = useSelector(getPartnerOrgData);
  const partnerOrgUsersData = usePartnerOrgUsers(
    (state) => state.partnerOrgUsersData
  );
  const getPartnerOrgUsers = usePartnerOrgUsers(
    (state) => state.getPartnerOrgUsers
  );
  const getEditPartnerUsersData = usePartnerOrgUsers(
    (state) => state.getEditPartnerUsersData
  );
  const getPartnerAssignedCustomer = usePartnerOrgUsers(
    (state) => state.getPartnerAssignedCustomer
  );
  const getPartnerAssignedCustomerData = usePartnerOrgUsers(
    (state) => state.getPartnerAssignedCustomerData
  );
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const partnerOrgId = partnerOrgData?.orgId;
  const policyData = partnerOrgData?.policies?.find(
    (item) => item.policyName === 'update_other_account'
  );
  const policyEditStatus = policyData ? true : false;
  const { keycloak } = useKeycloak();
  const token = keycloak.token;

  const [tokenData, setTokenData] = useState(null);
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [showUnlockSuccessModal, setShowUnlockSuccessModal] = useState(false);
  const [partnerUsersData, setPartnerUsersData] = useState([]);
  const [showLoader, setShowLoader] = useState(partnerOrgUsersData.length <= 0);
  const [modalTitleData, setModalTitleData] = useState(
    constants.PARTNER_NEW_USER_PAGE_TITLE
  );
  const [editModalTitleData, setEditModalTitleData] = useState(
    constants.EDIT_CUSTOMER_TITLE
  );
  const [editAccountData, setEditAccountData] = useState({});
  const [editAccountId, setEditAccountId] = useState(false);
  const [editAssignedCustomerData, setEditAssignedCustomerData] = useState([]);
  const [showEditOrgModal, setShowEditOrgModal] = useState(false);
  const [showAssignCustModal, setShowAssignCustModal] = useState(false);
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );

  const filterPartnerUsers = () => {
    let resultData = partnerOrgUsersData;
    if (resultData?.length >= 1 && loggedInUserData?.accountId) {
      setPartnerUsersData(
        resultData?.filter(
          (user) => user.accountId !== loggedInUserData.accountId
        )
      ); //=== Update state to load data
      setShowLoader(false);
    }
  };

  //=== Filter and store customer organizations user data to the state
  useEffect(() => {
    filterPartnerUsers();
  }, [partnerOrgUsersData, loggedInUserData]);

  const fetchData = async (token) => {
    if (token) {
      await getPartnerOrgUsers(`partner/orgs/${partnerOrgId}/accounts`);
    }
  };

  //=== Fetch the latest data
  useEffect(() => {
    if (token && !tokenData && partnerOrgId) {
      setTokenData(token);
      fetchData(token); //=== Fetch the partner - customer user accounts list
    }
  }, [partnerOrgId, token, tokenData]);

  const columnsData = useMemo(
    () => [
      {
        Header: constants.NAME_TABLE_HEADER,
        accessor: 'name',
        maxWidth: 200,
        minWidth: 50,
        width: 180,
        Cell: (props) => {
          let name = props.value;
          let allRecords = props?.data;
          let ifDataExists = allRecords?.find((row) => row?.accountId === props?.row?.original?.accountId);
          let letter = name
            .match(/(\b\S)?/g)
            .join('')
            .match(/(^\S|\S$)?/g)
            .join('')
            .toUpperCase();
          return (
            <span className="userName">
              <Avatar
                valueType={ifDataExists?.image?.url ? 'icon' : 'text'}
                value={
                  ifDataExists?.image?.url ? ifDataExists?.image?.url : letter
                }
                size="medium"
                avatarStyle="roundedCircle"
              />
              {name}
            </span>
          );
        },
        sortType: 'basic',
      },
      {
        Header: constants.EMAIL_TABLE_HEADER,
        accessor: 'email',
        sortType: 'basic',
      },
      {
        Header: constants.ROLE_TABLE_HEADER,
        accessor: 'role',
        maxWidth: 100,
        minWidth: 50,
        width: 70,
        Cell: (props) => {
          const roleValue = props.value;
          return (roleValue === roles.ROLE4PORTAL ||
            roleValue === roles.ROLE6PORTAL)
            ? constants.ADMIN_USER_ROLE_LABEL
            : constants.NORMAL_USER_ROLE_LABEL;
        },
        sortType: 'basic',
      },
      {
        Header: constants.PHONE_TABLE_HEADER,
        accessor: 'phoneNumber',
        maxWidth: 150,
        minWidth: 50,
        width: 130,
        sortType: 'basic',
      },
      {
        Header: constants.INCIDENTS_DETAILS_STATUS_TITLE,
        accessor: 'accountStatus',
        maxWidth: 150,
        minWidth: 50,
        width: 130,
        Cell: (props) => {
          const activeCheck = props.value;
          const isLocked = props.row.original.isLocked;
          return (
            <span className="activeIcon">
              {activeCheck === 'ACTIVE' && isLocked ? (
                <FaCircle size={10} className="status-icon-offline" />
              ) : activeCheck === 'ACTIVE' ? (
                <FaCircle size={10} className="status-icon-active" />
              ) : activeCheck === 'PENDING_ACTIVATION' ? (
                <FaCircle size={10} className="status-icon-inactive" />
              ) : (
                <FaCircle size={10} className="status-icon-disabled" />
              )}
              {activeCheck === 'ACTIVE' && isLocked
                ? constants.LOCKED_ACCOUNT_TEST
                : activeCheck === 'ACTIVE'
                ? constants.ACTIVE_USER_STATUS_LABEL
                : activeCheck === 'PENDING_ACTIVATION'
                ? constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE
                : constants.DEVICES_RETURN_DEACTIVATED_STATUS}
            </span>
          );
        },
        sortType: 'basic',
      },
      {
        Header: '',
        accessor: 'accountId',
        maxWidth: 50,
        minWidth: 50,
        width: 50,
        Cell: (props) => {
          return (
            <NavDropdown className="table-action-dropdown" title={'...'}>
              <NavDropdown.Item
                href="#edituser"
                onClick={() => handleEditUser(props.value)}
              >
                {constants.PARTNER_MANAGE_EDIT_USER_NAV_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#assigncust"
                onClick={() => handleAssignCustomers(props.value)}
              >
                {constants.PARTNER_MANAGE_ASSIGN_CUSTOMER_NAV_TITLE}
              </NavDropdown.Item>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const sortingData = useMemo(
    () => [
      {
        id: 'name',
        desc: true,
      },
      {
        id: 'email',
        desc: true,
      },
      {
        id: 'role',
        desc: true,
      },
      {
        id: 'accountStatus',
        desc: true,
      },
      {
        id: 'activeDate',
        desc: true,
      },
      {
        id: 'updatedDate',
        desc: true,
      },
    ],
    []
  );

  const handleModalTitleUpdate = (data) => {
    setModalTitleData(data);
  };

  const handleEditUser = async (userId) => {
    const ediUserData = await getEditPartnerUsersData(userId);
    await getPartnerAssignedCustomer(
      `partner/orgs/${partnerOrgId}/accounts/${ediUserData.accountId}/details`
    );
    setEditAccountData(ediUserData);
    setEditAccountId(userId);
    setShowEditOrgModal(true);
  };

  const handleEditModalTitleUpdate = (data) => {
    setEditModalTitleData(data);
  };

  const handleAssignCustomers = async (userId) => {
    if (userId && partnerOrgId) {
      await getPartnerAssignedCustomer(
        `partner/orgs/${partnerOrgId}/accounts/${userId}/details`
      );
      const ediUserData = await getPartnerAssignedCustomerData(partnerOrgId);
      setEditAssignedCustomerData(ediUserData);
      setEditAccountId(userId);
      setShowAssignCustModal(true);
    }
  };

  return (
    <div className="App">
      <Header />

      <PageWrapper className="mw-100">
        <div className="page-header">
          <Container className='mw-100'>
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="page-title text-start"
              >
                {constants.PARTNER_USERS_PAGE_TITLE}
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="text-end"
              >
                {getLoggedInUserPolicies()?.create_account && (
                  <PrimaryButton
                    className="btn btn-primary"
                    type="button"
                    width="auto"
                    onClick={() => {
                      setShowAddOrgModal(true);
                    }}

                  >
                    {constants.ADD_PARTNER_USER_BUTTON_TITLE}
                  </PrimaryButton>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="list-block">
          <Container className="h-100 mw-100">
            {getLoggedInUserPolicies()?.view_account &&
              (showLoader ? (
                <div className='position-absolute top-50 l-48'>
                   <SiteSpinner height="50px" width="50px" />
                   <div className='mt-2 text-dark'>{constants.LOADING}</div>
                </div>
              ) : (
                <UsersListTable
                  columns={columnsData}
                  defaultSortByData={sortingData}
                  data={partnerUsersData.filter(item => item?.role !== roles.ROLE6PORTAL)}
                />
              ))}
          </Container>
        </div>
      </PageWrapper>

      {/* New partner user - modal */}
      <SiteModal
        modalTitle={modalTitleData}
        showModal={showAddOrgModal}
        hideModal={() => setShowAddOrgModal(false)}
      >
        {/* To do : change later */}
        <NewPartnerUsers
          reloadData={() => {
            fetchData(token);
          }}
          modalTitle={constants.PARTNER_NEW_USER_PAGE_TITLE}
          hideModal={() => setShowAddOrgModal(false)}
          handleModalTitle={(data) => {
            handleModalTitleUpdate(data);
          }}
        />
      </SiteModal>

      {/* Edit partner user - modal */}
      <SiteModal
        modalTitle={`${editAccountData?.firstName} ${editAccountData?.lastName}`}
        showModal={showEditOrgModal}
        hideModal={() => setShowEditOrgModal(false)}
        classes="edit-partner-user-modal"
      >
        <EditPartnerUsers
          policyEditStatus={policyEditStatus}
          accountId={editAccountId}
          accountData={editAccountData}
          reloadData={() => {
            fetchData(token);
          }}
          hideModal={() => setShowEditOrgModal(false)}
          modalTitle={constants.EDIT_CUSTOMER_TITLE}
          handleModalTitle={(data) => {
            handleEditModalTitleUpdate(data);
          }}
          showUnlockModal={() => setShowUnlockSuccessModal(true)}
        />
      </SiteModal>

      {/* Assign customers(orgs) to partner user - modal */}
      <SiteModal
        modalTitle={constants.PARTNER_EDIT_ASIGN_CUSTOMER_POPUP_TITLE}
        showModal={showAssignCustModal}
        hideModal={() => setShowAssignCustModal(false)}
        classes="edit-partner-user-modal"
      >
        <AssignCustomers
          accountId={editAccountId}
          assignedCustomerData={editAssignedCustomerData}
          reloadData={() => {
            fetchData(token);
          }}
          hideModal={() => setShowAssignCustModal(false)}
          modalTitle={constants.EDIT_CUSTOMER_TITLE}
          handleModalTitle={(data) => {
            handleEditModalTitleUpdate(data);
          }}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.ACCOUNT_UNLOCKED}
        showModal={showUnlockSuccessModal}
        hideModal={() => setShowUnlockSuccessModal()}
        classes="unlock-success-modal"
      >
        <div className="d-flex flex-column align-items-center gap-4 text-center">
          <Avatar
            value={success}
            valueType="icon"
            size="extraLarge"
            style="roundedCircle"
          />
          <div className="w-100">
            <PrimaryButton
              className="btn btn-primary w-100"
              type="button"
              height="44px"
              fontSize="0.875rem"
              width="100%"
              onClick={() => setShowUnlockSuccessModal(false)}
            >
              {constants.BACK_TO_TECH_BUTTON_TEXT}
            </PrimaryButton>

            <PrimaryButton
              className="btn btn-outline-danger w-100 mt-2"
              type="button"
              height="44px"
              fontSize="0.875rem"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              onClick={() => {
                setShowUnlockSuccessModal(false);
                handleEditUser(editAccountId);
              }}
            >
              {`Edit ${editAccountData?.firstName} ${editAccountData?.lastName} Profile`}
            </PrimaryButton>
          </div>
        </div>
      </SiteModal>
    </div>
  );
};

export default ManagePartnerUsers;
