import { Col, Row, Container, Dropdown, NavDropdown, Nav} from "react-bootstrap";
import { useEffect, useState, useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import PageWrapper from "../../../pages/PageWrapper";
import { Header, BreadcrumbList, PrimaryButton, TextBlock, SiteModal } from "../../../components/common/";
import { constants, Utils, DeviceTypeEnum } from "../../../helpers/";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAccountId, getLocationsData, setDeviceInformation, setLocationsData, getAllMqttDataFromResponse, setFilteredDevicesData, setAllMqttDataFromResponse } from "../../../store/reducers/AccountReducer";
import AddDevice from "../addDevice/AddDevice";
import "../../../assets/css/loader.scss";
import { getMQTTConnection, getPlatformInfo, setLiveStream, setRemoteStream } from "../../../store/reducers/StreamingReducer";
import { usePoliciesStore } from "../../../store/policiesStore";
import { disconnectWithMQTT, mqttSubscribe } from "../../../utils/connection/mqttConnection";
import { useLoggedInUserData } from "../../../store/LoggedInAccountStore";
import useLicensesStore from "../../../store/LicensesStore";
import { useOrganizations } from "../../../store/OrganizationsStore";
import { IoIosSearch } from "react-icons/io";
import { HiOutlineChevronDown } from "react-icons/hi";
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { RiSettings5Fill } from "react-icons/ri";
import { FaCircle } from "react-icons/fa";
import { HiExclamationCircle, HiOutlineVideoCamera } from "react-icons/hi2";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import LatestSnapshot from "./LatestSnapshot";
import { v4 as uuidv4 } from "uuid";
import SiteSpinner from "../../../components/common/SiteSpinner";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as InfoCircleIcon } from "../../../assets/images/Info-circle.svg";
import { RxCross1 } from "react-icons/rx";
import { setNVRDeviceData, setManufacturerSKUList } from "../../../store/reducers/NVRDeviceReducer";
import { useAppsStore } from "../../../store/AppsStore";
import arrowLeft from "../../../assets/images/icons/Arrowleft.svg";
import dotshorizontal from "../../../assets/images/icons/Dotshorizontal.svg";
import { DefaultDevice } from "../../../assets/images";
import {
  getCustomerOrgData,
  setCustomerOrgData,
} from '../../../store/OrganizationsStoreIDB';
import {
  getAllDevicesData,
  // getAllMqttDataFromResponse,
  getSelectedOrganization,
  setAllDevicesData,
} from '../../../store/AccountStoreIDB';
import {
  getDevicesListOfCurrOrg,
  setAllScannedDevices,
  setDevicesListOfCurrOrg,
  setNVRScannedDevices,
} from '../../../store/NVRDeviceStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../../store/reducers/OrganizationsReducer';
import { VariableSizeList } from "react-window";

const DeviceList = () => {
    const orgInfo = useSelector(getOrgInfo)
    const [isDeviceAvailable,setIsDeviceAvailable] = useState(false)
    const [searchText, setSearchText] = useState("");
    const [licensesData, setLicensesData] = useState([]);
    const [cdnInfo, setCdnInfo] = useState({});
    const [currentSession, setCurrentSession] = useState(uuidv4());
    // const deviceListData = useSelector(getAllDevicesData);
    const [deviceListData, setDeviceListData] = useState([]);
    let [deviceData, setDeviceData] = useState([]);
    const [filteredDeviceData, setFilteredDeviceData] = useState([]);
    const mqttConnection = useSelector(getMQTTConnection);
    // const orgDetails = useSelector(getSelectedOrganization);
    const [orgDetails, setOrgDetails] = useState();
    const accountId = useSelector(getAccountId);
    const locationDetails = useSelector(getLocationsData);
    const [enableAttachAllLicenses, setEnableAttachAllLicenses] = useState(false);
    const platformDetails = useSelector(getPlatformInfo);
    const { getLicenses, setLicenses } = useLicensesStore();
    const navigate = useNavigate();
    const [showClaimDeviceModal, setShowClaimDeviceModal] = useState(false);
    const [showDeviceRemoveModal, setShowDeviceRemoveModal] = useState(false);
    const [deviceIdToBeRemoved, setDeviceIdToBeRemoved] = useState(null);
    const [userMsg, setUserMsg] = useState("");
    const [isDirectToScannedPopup, setIsDirectToScannedPopup] = useState(false);
    const [showClaimMobileDeviceModal, setShowClaimMobileDeviceModal] = useState(false);
    const [isDirectToScannedMobilePopup, setIsDirectToScannedMobilePopup] = useState(false);
    const [manufacturerData, setManufacturerData] = useState([]);
    const [showActiveModal, setShowActiveModal] = useState(false);
    const [showDeActiveModal, setShowDeActiveModal] = useState(false);
    const [selectedApp, setSelectedApp] = useState();
    const [deviceAppData, setDeviceAppData] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [mobileView, setMobileView] =useState(false);
    const [discovredDevciesCount, setDiscovredDevciesCount] = useState(0);
    const [reloadDeviceListData, setReloadDeviceListData] = useState(false);
    const getCustomerOrgPolicies = usePoliciesStore(
      (state) => state.getCustomerOrgPolicies
    );
    const [isDiscoverPopupLoading, setIsDiscoverPopupLoading] = useState(null);
    const allMqttData = useSelector(getAllMqttDataFromResponse);
    const getClassName = (deviceApps, itemId, deviceStatus) => {
        if (itemId === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD && deviceStatus !== 'PENDING_CLAIM') {
          return deviceApps?.[
            itemId === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
              ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
              : itemId
          ]
            ? "appActiveWithoutOnCloud"
            : "appDisableWithoutOnCloud";
        } else {
          return deviceApps?.[
            itemId === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
              ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
              : itemId
          ]
            ? "appActiveWithoutOnCloud"
            : "appDisableWithoutOnCloud";
        }
    }
    const filterOptions = [
        { value: "Online", label: "Online" },
        { value: "Offline", label: "Offline" },
        { value: "FirmwareUpdate", label: "Firmware Update" },
    ];
    let [filterList, setFilterList] = useState([]);
    const subscribeTopic = platformDetails?.mqtt?.topic_details?.subscribe?.settings;
    const getLoggedInUserUUID = useLoggedInUserData((state) => state.getLoggedInUserUUID);
    // const getCustomerOrgData = useOrganizations((state) => state.getCustomerOrgData);
    // const setCustomerOrgData = useOrganizations((state) => state.setCustomerOrgData);
    let appList = useAppsStore((state) => state.appList);
    appList = appList?.filter((item) => item?.name !== constants.FLEXAI_PROD_LABEL);
    const dispatch = useDispatch();
    // const devicesListOfCurrOrg = useSelector(getDevicesListOfCurrOrg);
    const [devicesListOfCurrOrgList, setDevicesListOfCurrOrgList] = useState([]);
    let [customerOrgPolicies, setCustomerOrgPolicies] = useState([]);
    //=== Store get/set actions
    // const getCustomerOrgDataValues = getCustomerOrgData()[0]?.policies;
    const deviceListRef = useRef();
    const sizeMap = useRef({});
    const [showListScrollLoader, setShowListScrollLoader] = useState(false);
    const [searchParams] = useSearchParams();
    const orgId = searchParams.get("orgId");
    const breadList = [
        { url: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)?.url, title: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)?.title },
        {
            url: `/landing-page.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}&fromPush=true`,
            title: `${orgInfo?.orgName ? orgInfo?.orgName : ''}`,
        },
        {
            url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
            title: constants.DEVICES_PAGE_TITLE,
        },
    ];
    const mobileBreadList=[
        {
           url: `/landing-page.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}&fromPush=true`,
           title: `${orgInfo?.orgName}`,
       },

   ]

    const loadSelectedOrgData = useCallback(async () => {
      const org = await getSelectedOrganization();
      setOrgDetails(org || {});
    }, []);

    const loadAllDevicesData = useCallback(async () => {
      const allDevices = await getAllDevicesData();
      setDeviceListData(allDevices);
    }, []);
  
    const loadDevicesListOfCurrOrgList = useCallback(async () => {
      const allDevices = await getDevicesListOfCurrOrg();
      setDevicesListOfCurrOrgList(allDevices || []);
    }, []);

    const loadCustomerOrgData = useCallback(async () => {
      const orgs = await getCustomerOrgData();
      setCustomerOrgPolicies(orgs?.[0]?.policies || []);
    }, []);

    const debouncedLoadCustomerOrgData = useDebouncedCallback(
      loadCustomerOrgData,
      1000
    );

    const debouncedLoadSelectedOrgData = useDebouncedCallback(
      loadSelectedOrgData,
      1000
    );
  
    const debouncedLoadAllDevicesData = useDebouncedCallback(
      loadAllDevicesData,
      1000
    );
  
    const debouncedLoadDevicesListOfCurrOrgList = useDebouncedCallback(
      loadDevicesListOfCurrOrgList,
      1000
    );

    useEffect(() => {
      const handleUpdate = async (data) => {
        if (data.key === 'selectedOrganization') {
          await debouncedLoadSelectedOrgData();
        }
        if (data.key === 'allDevices') {
          await debouncedLoadAllDevicesData();
        }
        if (data.key === 'customerOrgData') {
          await debouncedLoadCustomerOrgData();
        }
        if (data.key === 'devicesListOfCurrOrg') {
          await debouncedLoadDevicesListOfCurrOrgList();
        }
      };
      observerInstance.addObserver(handleUpdate);
      debouncedLoadSelectedOrgData();
      debouncedLoadAllDevicesData();
      debouncedLoadCustomerOrgData();
      debouncedLoadDevicesListOfCurrOrgList();
  
      return () => {
        observerInstance.removeObserver(handleUpdate);
      };
    }, [
      debouncedLoadSelectedOrgData,
      debouncedLoadAllDevicesData,
      debouncedLoadCustomerOrgData,
      debouncedLoadDevicesListOfCurrOrgList,
    ]);

    useEffect(() => {
        setShowLoader(true);
        localStorage.setItem("modalOpen", 0);
        fetchManufacturerData();
        fetchLicensesData();
        getLocations();
        fetchData();
        fetchDiscoveredDevicesCount();
        dispatch(setLiveStream(null));
        dispatch(setRemoteStream(null));
        dispatch(setDeviceInformation(null));
        if (mqttConnection) {
            disconnectWithMQTT();
        }
    }, []);

    useEffect(() => {
      const resetNVRScanData = async () => {
        await setNVRScannedDevices([]);
        await setAllScannedDevices([]);
      };
      resetNVRScanData();
    }, []);

    useEffect(() => {
      if(orgDetails?.orgId){
        fetchCdnInfo();
      }
    }, [orgDetails?.orgId]);

    useEffect(() => {
      const updateDetails = async (newlyAddedChannel) => {
        const updatedDeviceListData = JSON.parse(
          JSON.stringify(deviceListData)
        );
        updatedDeviceListData.push(newlyAddedChannel);
        await setAllDevicesData(updatedDeviceListData);

        const updatedDevicesListOfCurrOrg = JSON.parse(
          JSON.stringify(devicesListOfCurrOrgList)
        );
        updatedDevicesListOfCurrOrg.push(newlyAddedChannel);
        setAddedDeviceData(updatedDevicesListOfCurrOrg);
        await setDevicesListOfCurrOrg(updatedDevicesListOfCurrOrg);
      };
      if (
        allMqttData?.msg?.action === "add" &&
        allMqttData?.msg?.properties?.data?.channels?.length
      ) {
        const parentDeviceId = allMqttData?.msg?.resource
          ?.toString()
          ?.split("/")?.[1];
        let newlyAddedChannel;
        const rawFilteredDeviceData = JSON.parse(
          JSON.stringify(filteredDeviceData)
        );
        rawFilteredDeviceData.forEach(async (fDevice) => {
          if (fDevice.deviceId === parentDeviceId) {
            newlyAddedChannel = JSON.parse(
              JSON.stringify(allMqttData?.msg?.properties?.data?.channels?.[0])
            );
            if (Array.isArray(subscribeTopic) && subscribeTopic.length > 0) {
              const deviceSubscription = {
                topic: Utils.replaceStringValues(
                  subscribeTopic[0],
                  "${deviceId}",
                  `${newlyAddedChannel?.deviceId}`
                ),
                qos: 0,
              };
              mqttSubscribe(deviceSubscription);
            }
            if (
              !(
                constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO in
                newlyAddedChannel?.apps
              )
            ) {
              newlyAddedChannel.apps[
                constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO
              ] = false;
            }
            if (
              !(
                constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI in
                newlyAddedChannel?.apps
              )
            ) {
              newlyAddedChannel.apps[
                constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI
              ] = false;
            }
            if (
              !(
                constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND in
                newlyAddedChannel?.apps
              )
            ) {
              newlyAddedChannel.apps[
                constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND
              ] = false;
            }
            if (
              !(
                constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS in
                newlyAddedChannel?.apps
              )
            ) {
              newlyAddedChannel.apps[
                constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
              ] = false;
            }

            if (newlyAddedChannel?.capability) {
              let response = await fetch(newlyAddedChannel.capability.url);
              if (response.status === 200) {
                let responseJson = await response.json();
                newlyAddedChannel.capDetails = responseJson;
              }
            }
            fDevice.childDevices = [newlyAddedChannel];
          }
        });
        addDeviceProperties(rawFilteredDeviceData);
        updateDetails(newlyAddedChannel);
      }
    }, [allMqttData]);

    // TODO : Below code chanegs are in obeservation
    // useEffect(() => {
    //   setDeviceData(deviceListData);
    //   setFilteredDeviceData(deviceListData);
    // }, [deviceListData])


    useEffect(() => {
        const data = JSON.parse(JSON.stringify(deviceData));
        addDeviceProperties(data);
    }, [licensesData]);


    const removeAppLink = async () => {
        try {
            let appList = JSON.parse(JSON.stringify(deviceAppData))
            delete appList[selectedApp.appLinkId];
            const reqBody = {
                apps: [selectedApp.appLinkId],
            };
            const res = await axios.post(
                `/device/orgs/${orgId}/devices/${selectedApp.device.deviceId}/unclaim/app`,
                reqBody,
                Utils.requestHeader()
            );
            if (parseInt(res?.status) === 200) {
                setShowDeActiveModal(false);
                fetchData();
            }
        } catch (error) {
            // TODO : to print the error will update this later
            console.error(error);
        }
        // );
    };
    const addAppLink = async () => {
        setShowLoader(true)
        try {
            const reqBody = {
                apps: [selectedApp.appLinkId],
            };
            const res = await axios.post(
                `/device/orgs/${orgId}/devices/${selectedApp.device.deviceId}/claim/app`,
                reqBody,
                Utils.requestHeader()
            );
            if (parseInt(res?.status) === 200) {
                setShowActiveModal(false);
                fetchData();
                setShowLoader(false)
            }
        } catch (error) {
            console.error(error);
            setShowLoader(false)
        }
        // );
    };

    const onActiveLinked = (device, appLinkId, pIndex) => {
        const dataValue = {
            device: device,
            appLinkId: (appLinkId == constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD) ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS : appLinkId,
            pIndex: pIndex,

        };
        setDeviceAppData(device.apps);
        setSelectedApp(dataValue);
        setShowActiveModal(true);
    };

    const onDeActiveLinked = (device, appLinkId) => {
        const dataValue = {
            device: device,
            appLinkId: (appLinkId == constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD) ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS : appLinkId,
        }
        setDeviceAppData(device.apps);
        setSelectedApp(dataValue);
        setShowDeActiveModal(true);
    };
    const addDeviceProperties = (groupedDeviceData) => {
        groupedDeviceData?.length &&
            groupedDeviceData?.forEach((device) => {
              const avlbleDeviceVersion = device?.properties?.['avlbl-device-ver'];
              const firmwareVersion = device?.properties?.['firmware-version'];
              const checkStatus = (avlbleDeviceVersion && firmwareVersion) ? avlbleDeviceVersion?.toLowerCase() !== firmwareVersion?.toLowerCase() : false

                device["displayDeviceStatus"] = Utils.getDeviceStatus(device.deviceStatus, device.connectionStatus);
                device["isFirmwareUpgradeAvailable"] = device?.properties && device?.properties?.["avlbl-duclo-ver"] ? 
                  device?.properties?.["avlbl-duclo-ver"] !== device?.properties?.["edge-app-version"] : avlbleDeviceVersion ? avlbleDeviceVersion?.toLowerCase() != firmwareVersion?.toLowerCase(): false;
                  if (!device['isFirmwareUpgradeAvailable']) {
                    device['isFirmwareUpgradeAvailable'] = checkStatus;
                  }

                device["displayLicensesStatus"] = getLicensesStatus(device); 
                device["displayArea"] = locationDetails?.map((location) => {
                    if (location.locationId === device.locationId) {
                        return location?.areas?.map((area) => {
                            if (area.areaId === device.areaId) {
                                return area.areaName;
                            }
                        });
                    }
                });
                device["displayLocation"] = locationDetails?.map((location) => {
                    if (location.locationId === device.locationId) {
                        return location.locationName;
                    }
                });
                device.childDevices &&
                    device.childDevices.forEach((child) => {
                      const childAvlbleDeviceVersion = child?.properties?.["avlbl-device-ver"]
                      const childFirmwareVersion = child?.properties?.["firmware-version"]
                      const checkStatus = childAvlbleDeviceVersion && childFirmwareVersion?.toLowerCase() !== childFirmwareVersion?.toLowerCase();

                        child["displayDeviceStatus"] = Utils.getDeviceStatus(child.deviceStatus, child.connectionStatus);
                        child["isFirmwareUpgradeAvailable"] = child?.properties && child?.properties?.["avlbl-duclo-ver"] ? 
                          child?.properties?.["avlbl-duclo-ver"] !== child?.properties?.["edge-app-version"] : false;
                          if(!child["isFirmwareUpgradeAvailable"] && checkStatus){
                            child['isFirmwareUpgradeAvailable'] = checkStatus;
                          }
                        child["displayLicensesStatus"] = getLicensesStatus(child);
                        child["displayArea"] = locationDetails?.map((location) => {
                            if (location.locationId === child.locationId) {
                                return location?.areas?.map((area) => {
                                    if (area.areaId === child.areaId) {
                                        return area.areaName;
                                    }
                                });
                            }
                        });
                        child["displayLocation"] = locationDetails?.map((location) => {
                            if (location.locationId === child.locationId) {
                                return location.locationName;
                            }
                        });
                    });
            });
        setDeviceData(groupedDeviceData);
        setFilteredDeviceData(groupedDeviceData);
        dispatch(setFilteredDevicesData(groupedDeviceData));
    };

    useEffect(() => {
        applyfilters();
    }, [JSON.stringify(filterList), searchText]);

    const mobileModalView=()=>{
       setMobileView(true);
    }
    
    useEffect(() => {
      setReloadDeviceListData(!reloadDeviceListData);
    }, [deviceListData]);
    
    useEffect(() => {
      updateConnectionStatus();
    }, [reloadDeviceListData]);
    
    const updateConnectionStatus = () => {
      const oldDeviceData = JSON.parse(JSON.stringify(deviceData));
      let isUpdated = false;
      oldDeviceData.forEach((deviceD) => {
        const statusDevice = deviceListData?.find(
          (deviceL) => deviceL.deviceId === deviceD.deviceId
        );
        if (
          statusDevice &&
          statusDevice.deviceStatus &&
          statusDevice.deviceStatus !== deviceD.deviceStatus
        ) {
          isUpdated = true;
          deviceD['deviceStatus'] = statusDevice.deviceStatus;
          deviceD['displayDeviceStatus'] = Utils.getDeviceStatus(
            statusDevice.deviceStatus,
            deviceD.connectionStatus
          );
        }
        if (
          statusDevice &&
          statusDevice.connectionStatus &&
          statusDevice.connectionStatus !== deviceD.connectionStatus
        ) {
          isUpdated = true;
          deviceD['connectionStatus'] = statusDevice.connectionStatus;
          deviceD['displayDeviceStatus'] = Utils.getDeviceStatus(
            deviceD.deviceStatus,
            statusDevice.connectionStatus
          );
        }
        deviceD.childDevices &&
          deviceD.childDevices.forEach((child) => {
            const childStatusDevice = deviceListData?.find(
              (deviceL) => deviceL.deviceId === child.deviceId
            );
  
            if (
              childStatusDevice &&
              childStatusDevice.deviceStatus &&
              childStatusDevice.deviceStatus !== child.deviceStatus
            ) {
              isUpdated = true;
              child['deviceStatus'] = childStatusDevice.deviceStatus;
              child['displayDeviceStatus'] = Utils.getDeviceStatus(
                childStatusDevice.deviceStatus,
                child.connectionStatus
              );
            }
            if (
              childStatusDevice &&
              childStatusDevice.connectionStatus &&
              childStatusDevice.connectionStatus !== child.connectionStatus
            ) {
              isUpdated = true;
              child['connectionStatus'] = childStatusDevice.connectionStatus;
              child['displayDeviceStatus'] = Utils.getDeviceStatus(
                child.deviceStatus,
                childStatusDevice.connectionStatus
              );
            }
          });
      });
      if (isUpdated) {
        setDeviceData(oldDeviceData);
        applyfilters(oldDeviceData);
      }
    };
    
    const applyfilters = (data) => {
        const dataToBeUpdated = data ? data : deviceData;
        if (!filterList.length && !searchText) {
            setFilteredDeviceData(dataToBeUpdated);
            dataToBeUpdated?.length <= 0 ? setIsDeviceAvailable(true) : setIsDeviceAvailable(false)
        } else if (!filterList.length && searchText) {
            applySearchFilters(dataToBeUpdated);
        } else {
            let list = [];
            filterList.forEach((option) => {
                if (option === "Online" || option === "Offline") {
                    let subList = [];
                    subList = dataToBeUpdated.filter((device) => device.displayDeviceStatus === option);
                    list = [...list, ...subList];
                }
                if (option === "FirmwareUpdate") {
                    let subList = [];
                    subList = dataToBeUpdated.filter((device) => device?.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS && device.isFirmwareUpgradeAvailable);
                    list = [...list, ...subList];
                }
                list = list.filter((currentElement, index, elementsArray) => index === elementsArray.findIndex(((element) => (element === currentElement))));
            });
            if (searchText) {
                applySearchFilters(list);
            } else {
                setFilteredDeviceData(list);
                list?.length <= 0 ? setIsDeviceAvailable(true) : setIsDeviceAvailable(false)
            }
        }
    };

    const applySearchFilters = (dataList) => {
      dataList = JSON.parse(JSON.stringify(dataList));
      const serchedDevices = [];
      dataList.forEach((dataDevice) => {
        if (
          dataDevice?.deviceName
            ?.toUpperCase()
            .includes(searchText.toUpperCase()) ||
          dataDevice?.serialNo?.toUpperCase().includes(searchText.toUpperCase())
        ) {
          serchedDevices.push(dataDevice);
        } else {
          const filteredChild = dataDevice?.childDevices?.filter(
            (child) =>
              child?.deviceName
                ?.toUpperCase()
                .includes(searchText.toUpperCase()) ||
              child?.serialNo?.toUpperCase().includes(searchText.toUpperCase())
          );
          if (filteredChild.length) {
            dataDevice.childDevices = filteredChild;
            serchedDevices.push(dataDevice);
          }
        }
      });
      setFilteredDeviceData(serchedDevices);
      serchedDevices?.length <= 0 ? setIsDeviceAvailable(true) : setIsDeviceAvailable(false)
    };

    const onClickFilter = (option) => {
        let newFilterList = [];
        if (option === "selectAll") {
            filterOptions.forEach((item) => {
                newFilterList.push(item.value);
            });
        } else if (filterList.includes(option)) {
            newFilterList = filterList.filter((item) => item !== option);
        } else {
            newFilterList = JSON.parse(JSON.stringify(filterList));
            newFilterList.push(option);
        }
        setFilterList(newFilterList);
    };

    const onChangeSearchText = (e) => {
        setSearchText(e?.target?.value);
    };

    const getCapabiltiesFromDeviceList = async (data) => {
        let deviceList = [...data];
        let newDeviceList = deviceList?.map(async (device, index) => {
            if (Array.isArray(subscribeTopic) && subscribeTopic.length > 0) {
                const deviceSubscription = {
                    topic: Utils.replaceStringValues(subscribeTopic[0], "${deviceId}", `${device?.deviceId}`),
                    qos: 0,
                };
                mqttSubscribe(deviceSubscription);
            }
            let deviceInfo;
            if (device?.capability) {
                const parentDevice = deviceListData?.find((parents) => parents?.deviceId === device?.gatewayId);
                if (parentDevice?.childDevices?.length) {
                    deviceInfo = parentDevice?.childDevices?.find((childs) => childs?.deviceId === device?.deviceId);
                }
            }
            // let isCapabilityCallRequired = deviceInfo?.capDetails === undefined || deviceInfo?.capability?.version !== device?.capability?.version;

            // if (device?.capability && isCapabilityCallRequired) {
            //     let response = await fetch(device.capability.url);
            //     if (response.status === 200) {
            //         let responseJson = await response.json();
            //         return { ...device, capDetails: responseJson };
            //     } else {
            //         return { ...device };
            //     }
            // } else 
            if (device?.capability && deviceInfo?.capability?.version === device?.capability?.version && deviceInfo.capDetails) {
                return { ...device, capDetails: deviceInfo?.capDetails };
            } else {
                return { ...device };
            }
        });
        const deviceCapability = await Promise.all(newDeviceList);
        const groupedDeviceData = Utils.getGroupedDevices(deviceCapability);
        addDeviceProperties(groupedDeviceData);
        await setAllDevicesData(JSON.parse(JSON.stringify(deviceCapability)));
        deviceCapability?.length <= 0 ? setIsDeviceAvailable(true) : setIsDeviceAvailable(false)
        setShowLoader(false);
    };

    const setAddedDeviceData = async (responseData) => {
        let custOrg = await getCustomerOrgData();
        const deviceInfo = custOrg?.find((custOrg) => custOrg?.orgId === orgId);
        let allDevice = { ...deviceInfo, devices: responseData };
        let list = custOrg?.map((org, i) => {
            if (org?.orgId === orgId) {
                return allDevice;
            } else {
                return { ...org };
            }
        });
        let customerOrg = await Promise.all(list);
        await setCustomerOrgData(customerOrg);
    };

    const fetchData = async () => {
        setIsDirectToScannedPopup(false);
        setIsDirectToScannedMobilePopup(false);
        setShowLoader(true);
        await axios.get(`device/orgs/${orgId}/devices`, Utils.requestHeader()).then(async (response) => {
            const resultData = response.data;
            if (resultData) {
                const responseMeta = resultData.meta;
                const responseData = resultData.data;

                const updatedResponseData = responseData && responseData?.map((item) => {
                  if (!(constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO in item?.apps)) {
                    item.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO] = false;
                  }
                  if (!(constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI in item?.apps)) {
                    item.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI] = false;
                  }
                  if (!(constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND in item?.apps)) {
                    item.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND] = false;
                  }
                  if (!(constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS in item?.apps)) {
                    item.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS] = false;
                  }
                  return item;
                });

                if (responseMeta && (responseMeta.code === 200 || responseMeta.code === "200")) {
                    getCapabiltiesFromDeviceList(updatedResponseData);
                    // const devicePropertyErrorSubscribe = {
                    //     topic: `d/notify/${accountId}/${getLoggedInUserUUID()}`,
                    //     qos: 0,
                    // };
                    // mqttSubscribe(devicePropertyErrorSubscribe);
                    const updatedData = updatedResponseData.filter((device) => device.deviceStatus !== "DEACTIVATED");
                    setAddedDeviceData(updatedData);
                    await setDevicesListOfCurrOrg(updatedData);
                }
                else{
                  setIsDeviceAvailable(true)
                  setShowLoader(false);
                }  
            }else{
                setIsDeviceAvailable(true)
                setShowLoader(false);
            }
        });
    };

    const fetchDiscoveredDevicesCount = async () => {
      await axios
        .get(
          `/device/orgs/${orgId}/devices/discover/count`,
          Utils.requestHeader()
        )
        .then((response) => {
          const resultData = response.data;
          if (resultData) {
            const responseMeta = resultData.meta;
            const responseData = resultData.data;
            if (
              responseMeta &&
              (responseMeta.code === 200 || responseMeta.code === '200')
            ) {
              setDiscovredDevciesCount(responseData.count);
            }
          }
        });
    };
    
    const getLocations = async () => {
        await axios.get(`partner/orgs/${orgId}/locations`, Utils.requestHeader()).then((response) => {
            const resultData = response.data;
            if (resultData) {
                const responseMeta = resultData.meta;
                const responseData = resultData.data;
                if (responseMeta && (responseMeta.code === 200 || responseMeta.code === "200")) {
                    const data = [...responseData];
                    const defaultLocation = data?.filter((location) => location.isDefault === "true");
                    const finalData = data.sort(function (x, y) {
                        return x === defaultLocation[0] ? -1 : y === defaultLocation[0] ? 1 : 0;
                    });
                    dispatch(setLocationsData(finalData));
                }
            }
        });
    };

    const fetchCdnInfo = useCallback(async () => {
        try {
            // Retrieve the CDN info
            const result = await axios.get(`/partner/orgs/${orgDetails?.orgId}/cdn`, {
                params: {
                    requestTime: Date.now(),
                    requestId: currentSession,
                },
                ...Utils.requestHeader(currentSession),
                timeout: 30000,
                credentials: "include",
                withCredentials: true,
            });

            const response = result?.data;

            if (response?.meta?.code === 200) {
                setCdnInfo(response.data?.cdn);
            }
        } catch (err) { }
    });

    const fetchManufacturerData = async () => {
        await axios.get("/device/manufacturerSkuBanks", Utils.requestHeader()).then((response) => {
            const resultData = response.data;
            if (resultData?.meta?.code === 200) {
                const responseData = resultData.data;
                setManufacturerData(responseData);
                dispatch(setManufacturerSKUList(responseData));
            } else {
                setManufacturerData([]);
                dispatch(setManufacturerSKUList([]));
            }
        });
    };

    const fetchLicensesData = async () => {
        let licensesLoadSuccess = await setLicenses(`/user/orgs/${orgId}/licenses`);

        if (licensesLoadSuccess?.status === "success") {
            setLicensesData(getLicenses());
        }
    };

    const getLicensesStatus = (device) => {
        const deviceLicense = licensesData.filter((license) => license.deviceId === device.deviceId)[0];
        if (deviceLicense?.expiryDate && Utils.getDateDifferenceInDays(deviceLicense?.expiryDate, moment().valueOf()) <= 0) {
            return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED;
        } else {
            if (deviceLicense?.licenseStatus === "ASSIGNED_ACTIVATION_PENDING") {
                return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING + " " + moment(deviceLicense?.activeDate).format("MMM Do YYYY");
            } else if (deviceLicense?.licenseStatus !== "UNASSIGNED" && deviceLicense?.expiringSoonInDays && Utils.getDateDifferenceInDays(deviceLicense.expiryDate, moment().valueOf()) <= deviceLicense?.expiringSoonInDays && Utils.getDateDifferenceInDays(deviceLicense.expiryDate, moment().valueOf()) >= 0) {
                return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON;
            } else if (deviceLicense?.licenseStatus === "ACTIVE_UNASSIGNED" || deviceLicense?.licenseStatus === "UNASSIGNED") {
                return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE;
            } else if (deviceLicense?.licenseStatus === "ACTIVE") {
                return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE;
            } else if (deviceLicense?.licenseStatus === "UNASSIGNED") {
                return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE;
            } else {
                return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE;
            }
        }
    };

    const getDeviceStatus = (device) => {
        if (device.deviceStatus?.toUpperCase() === constants.DEVICES_DEACTIVATED_DEVICE_STATUS) {
            return (
                <div>
                    <FaCircle className="status-indicator status-ready-to-claim" />
                    {device.deviceStatus}
                </div>
            );
        } else if (device.displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS) {
            return (
                <div>
                    <FaCircle className="status-indicator status-online" /> {device.displayDeviceStatus}
                </div>
            );
        } else if (device.displayDeviceStatus === constants.DEVICES_RETURN_READY_TO_CLAIM_STATUS) {
            return (
                <div>
                    <FaCircle className="status-indicator status-ready-to-claim" /> {device.displayDeviceStatus}
                </div>
            );
        } else if (device.displayDeviceStatus === constants.DEVICES_RETURN_ENTERED_STATUS) {
            return (
                <div>
                    <FaCircle className="status-indicator status-entered" /> {device.displayDeviceStatus}
                </div>
            );
        } else if (device.displayDeviceStatus === constants.DEVICES_RETURN_CLAIMING_STATUS) {
            return (
                <div>
                    <FaCircle className="status-indicator status-claiming" />
                    {device.displayDeviceStatus}
                </div>
            );
        } else if (device.displayDeviceStatus === constants.DEVICES_RETURN_DEACTIVATED_STATUS) {
            return (
                <div>
                    <FaCircle className="status-indicator status-offline" />
                    {device.displayDeviceStatus}
                </div>
            );
        }
        else {
            return (
                <div>
                    <FaCircle className="status-indicator status-entered" />
                    {device.displayDeviceStatus}
                </div>
            );

        }
    };

    // const showDeviceDashboard = (data) => {
    //     dispatch(setDeviceInformation(data));
    //     data?.deviceId && navigate(`/devices/dashboard.html?deviceId=${data?.deviceId}&orgId=${orgDetails?.orgId}`);
    // };

    const getCapabiltiesForDevice = async (deviceData) => {
      if (deviceData?.capability) {
        try {
          const response = await fetch(deviceData?.capability?.url);
          if (response.status === 200) {
            const responseJson = await response.json();
            return responseJson;
          } else {
            return {};
          }
        } catch (error) {
          return {};
        }
      } else {
        return {};
      }
    };
  
    const showChannelDetails = async (data) => {
        const capDetails = await getCapabiltiesForDevice(data);
        localStorage.setItem('localStoreProperty', JSON.stringify(data.properties));
        dispatch(setDeviceInformation({ ...data, capDetails: capDetails }));
        data?.deviceId && navigate(`/devices/channel-details.html?deviceId=${data?.deviceId}&orgId=${orgDetails?.orgId}`);
    };

    // ToDo: Not Claimed related code
    // const onClaimDevices = (device) => {
    //   setShowClaimDeviceModal(true);
    // };

    const deleteDeviceCall = async () => {
        try {
            const res = await axios.delete(`/device/orgs/${orgDetails?.orgId}/devices/${deviceIdToBeRemoved}/v2`, Utils.requestHeader());
            if ( parseInt(res?.status) === 202 || parseInt(res?.data?.meta?.code) === 200) {
                setShowLoader(false);
                clearRemoveDeviceData();
                fetchData();
            } else {
                setUserMsg(res?.meta?.userMsg || res?.data?.meta?.userMsg);
            }
        } catch (error) {
            // TODO : to print the error will update this later
            console.error(error);
        }
    };

    const onDeleteDevice = (deviceId) => {
        setDeviceIdToBeRemoved(deviceId);
        setShowDeviceRemoveModal(true);
    };

    const clearRemoveDeviceData = () => {
        setShowDeviceRemoveModal(false);
        setDeviceIdToBeRemoved(null);
        setUserMsg("");
    };

    const handleScanNetworkForDeviceClick = async (device) => {
      setIsDiscoverPopupLoading(device?.deviceId);
      // await setNVRScannedDevices([]);
      // await setAllScannedDevices([]);
        axios.get(`device/orgs/${orgDetails?.orgId}/devices/${device?.deviceId}/v2`, Utils.requestHeader()).then(async (response) => {
            const resultData = response.data;
            if (resultData) {
                const responseMeta = resultData.meta;
                const responseData = resultData.data;
                if (responseMeta && (responseMeta.code === 200 || responseMeta.code === "200")) {
                    setIsDiscoverPopupLoading(null);
                    dispatch(setAllMqttDataFromResponse(null));
                    setIsDirectToScannedPopup(true);
                    dispatch(
                        setNVRDeviceData({
                            ...responseData,
                            channelCount: manufacturerData?.find((item) => item.model === responseData.modelNumber)?.channelCount,
                            childDevices: device.childDevices,
                        })
                    );
                    await setNVRScannedDevices([]);
                    await setAllScannedDevices([]);
                } else {
                    setIsDiscoverPopupLoading(null);
                    console.log("Error - ", responseMeta.userMsg);
                }
            }
        });
    };
    //For mobile responsive
    const handleScanNetworkForMobileDeviceClick = async (device) => {
      setIsDiscoverPopupLoading(device?.deviceId);
      // await setNVRScannedDevices([]);
      // await setAllScannedDevices([]);
      axios.get(`device/orgs/${orgDetails?.orgId}/devices/${device?.deviceId}/v2`, Utils.requestHeader()).then(async (response) => {
          const resultData = response.data;
          if (resultData) {
              const responseMeta = resultData.meta;
              const responseData = resultData.data;
              if (responseMeta && (responseMeta.code === 200 || responseMeta.code === "200")) {
                setIsDiscoverPopupLoading(null);
                dispatch(setAllMqttDataFromResponse(null));
                setIsDirectToScannedMobilePopup(true);
                  dispatch(
                      setNVRDeviceData({
                          ...responseData,
                          channelCount: manufacturerData?.find((item) => item.model === responseData.modelNumber)?.channelCount,
                          childDevices: device.childDevices.map((item) => {
                            return item.properties?.['ip-address'] === '172.25.6.152'
                              ? { ...item, connectionStatus: 'offline' }
                              : item;
                          }),
                      })
                  );
                  await setNVRScannedDevices([]);
                  await setAllScannedDevices([]);
              } else {
                  setIsDiscoverPopupLoading(null);
                  console.log("Error - ", responseMeta.userMsg);
              }
          }
      });
  };

    const appIconActionCheck = (device, itemId, index, deviceStatus) => {
        if ((itemId === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD || itemId === constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS) && deviceStatus !== 'PENDING_CLAIM') {
            device.apps?.[itemId === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
              ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
              : itemId]
            ? onDeActiveLinked(device, itemId)
            : onActiveLinked(device, itemId, index);
        }else{
            return;
        }
    }

    const getSize = (index) => {
      if (sizeMap?.current?.[index]) {
        return sizeMap?.current?.[index] + 12;
      } else {
        if (
          filteredDeviceData?.[index]?.childDevices &&
          filteredDeviceData?.[index]?.childDevices.length > 1
        ) {
          return 105;
        } else {
          return 217;
        }
      }
    };
    
    const setSize = useCallback((index, size) => {
      sizeMap.current = { ...sizeMap.current, [index]: size };
      if (deviceListRef.current) {
        // Remove resetAfterIndex(i) would remain the component's state.
        deviceListRef.current.resetAfterIndex(index);
      }
    }, []);
  
    const toggleItem = (index) => {
      // Reset size after toggling expansion
      setTimeout(() => {
        const ele = document.getElementById(`div${index}`);
        if (ele) {
          const newSize = ele?.offsetHeight; // Expanded size is based on the number of items expanded in the list
          setSize(index, newSize);
        }
      }, 0);
    };

    useEffect(() => {
      if(!filteredDeviceData?.length){
        setShowListScrollLoader(false);
      }
    }, [filteredDeviceData]);

    const ShowOrgSingletoneDevices = ({ index, style, isScrolling }) => {
      // DON'T REMOVE ANY CODE OR COMMENT HERE
    const device = filteredDeviceData?.[index];
    const rowRef = useRef({});

    if (isScrolling) {
      setShowListScrollLoader(true)
    } else {
      setShowListScrollLoader(false)
    }

    useEffect(() => {
      if (rowRef.current) {
        setSize(index, rowRef.current.clientHeight);
      }
      // eslint-disable-next-line
    }, [rowRef]);

      return (filteredDeviceData?.length && !isDeviceAvailable) ? (
        <div style={style} className='device-list-ref'>
          {(device.childDevices && device.childDevices.length > 1) ||
          device?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR ||
          device?.deviceType?.toUpperCase() === DeviceTypeEnum.DMSERVER ? (
            <Accordion.Item
              ref={rowRef}
              id={`div${index}`}
              key={device.deviceId}
              eventKey={index.toString()}
              className="accordion-item"
            >
              <Accordion.Header>
                <div className="header me-2 w-100 d-none d-md-flex device-name-header">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      wordBreak: "break-word",
                    }}
                    className="accordion-parent-device-name-first"
                  >
                    <div className="image-wrapper">
                          <img
                            src={device?.imageURL}
                            onError={(e) => e.target.src = `${DefaultDevice}`}
                            id={`parent${device?.deviceId}`}
                            alt=""
                            className="device-icon"
                          />
                      
                    </div>
                    <div className="device-name">{device.deviceName}</div>
                    {device?.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS && device.isFirmwareUpgradeAvailable && device?.deviceType?.toUpperCase() !== DeviceTypeEnum.DMSERVER ? (
                      <div className="firmware-update">
                        <div className="update-text">
                          {constants.DEVICES_FIRMWARE_UPGRADE_AVAILABLE}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {(device?.deviceType?.toUpperCase() ===
                      DeviceTypeEnum.NVR ||
                      device?.deviceType?.toUpperCase() ===
                        DeviceTypeEnum.DMSERVER) && (
                        <PrimaryButton
                          className="device-btn-list scan-for-network-button btn-primary"
                          type="button"
                          width="200px"
                          backgroundColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--primary_40")}
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--brand_white")}
                          fontSize="14px"
                          height="44px"
                          // lineHeight="33px"
                          disabled={
                            device?.deviceStatus ===
                              constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
                            device?.deviceStatus ===
                              constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS || 
                            device.connectionStatus === 
                              constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
                            !getCustomerOrgPolicies()?.install_device
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleScanNetworkForDeviceClick(device);
                          }}
                          loader={isDiscoverPopupLoading === device.deviceId}
                        >
                          {constants.SCAN_NETWORK_FOR_DEVICE_BUTTON}
                        </PrimaryButton>

                    )}
                    <RiSettings5Fill
                      size={24}
                      className={"setting-icon"}
                      onClick={(e) => {
                        e.preventDefault();
                        showChannelDetails(device);
                      }}
                    />
                  </div>
                </div>
              {/* start code for mobile responsive device */}
             <div className="header me-2 w-100 d-flex d-md-none">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      wordBreak: "break-word",
                    }}
                  >
                    <div className="image-wrapper">
                      <img
                        src={device?.imageURL}
                        alt=""
                        className="device-icon"
                      />
                    </div>
                    <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {device.deviceName}
                              </Tooltip>
                            }
                          >
                    <div className="deviceName-Break">{device.deviceName}</div>
                    </OverlayTrigger>
                    {/* {device.isFirmwareUpgradeAvailable ? (
                      <div className="firmware-update">
                        <div className="update-text">
                          {constants.DEVICES_FIRMWARE_UPGRADE_AVAILABLE}
                        </div>
                      </div>
                    ) : null} */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <RiSettings5Fill
                      size={24}
                      className={"setting-icon"}
                      onClick={(e) => {
                        e.preventDefault();
                        showChannelDetails(device);
                      }}
                    />
                  </div>
                </div>
              {/* end code for mobile responsive device */}
              </Accordion.Header>
               {/* start code for mobile responsive device */}
              <Row className="d-flex d-md-none">
                    <Col className="settings-block" xs={12} md={12}>
                    {device?.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS && device.isFirmwareUpgradeAvailable && device?.deviceType?.toUpperCase() !== DeviceTypeEnum.DMSERVER ? (
                      <div className="firmware-update">
                        <div className="update-text">
                          {constants.DEVICES_FIRMWARE_UPGRADE_AVAILABLE}
                        </div>
                      </div>
                    ) : null}
                    </Col>
                    <Col xs={12} md={12}>
                    {(device?.deviceType?.toUpperCase() ===
                      DeviceTypeEnum.NVR ||
                      device?.deviceType?.toUpperCase() ===
                        DeviceTypeEnum.DMSERVER) && (
                      <>
                    <PrimaryButton
                          className="device-btn-list"
                          type="button"
                          width="100%"
                          backgroundColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--primary_40")}
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--brand_white")}
                          fontSize="14px"
                          height="44px"
                         // lineHeight="33px"
                          disabled={
                            device?.deviceStatus ===
                              constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
                            device?.deviceStatus ===
                              constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS || device.connectionStatus === constants.DEVICES_OFFLINE_CONNECTION_STATUS
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleScanNetworkForMobileDeviceClick(device);
                          }}
                          loader={isDiscoverPopupLoading === device.deviceId}
                        >
                          {constants.SCAN_NETWORK_FOR_DEVICE_BUTTON}
                        </PrimaryButton>
                        </>
                    )}
                    </Col>
              </Row>
              {/* end code for mobile responsive device */}
              <Accordion.Body 
              onEntered={() => toggleItem(index)}
              onExited={() => toggleItem(index)}
              >

                {device.childDevices.map((childDevice, childIndex) => (
                  <>
                  <div
                    key={childDevice.deviceId + childIndex}
                    className="camera d-none d-md-flex"
                    // onClick={() => {
                    //   if (
                    //     device.deviceType.toUpperCase() !==
                    //     DeviceTypeEnum.DMSERVER
                    //   )
                    //     showDeviceDashboard(childDevice);
                    // }}
                  >
                    <LatestSnapshot
                      key={childDevice.deviceId}
                      deviceElemId={`camera${index}${childIndex}`}
                      orgId={orgDetails?.orgId}
                      cdnInfo={cdnInfo}
                      locationAreaName={childDevice.locationAreaNames}
                      isHighlighted={false}
                      showZoomOptions={false}
                      conStatus={childDevice.connectionStatus || "offline"}
                      hubId={device.deviceId}
                      defaultImage={childDevice?.imageURL}
                      {...childDevice}
                    />
                    <div className="camera-details">
                      <div className="camera-name">
                        {childDevice.deviceName}
                      </div>
                      <div className="detail-table">
                        <div className="table-column">
                          <div className="table-header">
                            {constants.DEVICES_LIST_LOCATION}
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>{childDevice.displayLocation}</Tooltip>
                            }
                          >
                            <div className="table-info width-120">
                              {childDevice.displayLocation}
                            </div>
                          </OverlayTrigger>
                        </div>
                        <div className="table-column">
                          <div className="table-header">
                            {constants.DEVICES_LIST_AREA}
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>{childDevice.displayArea}</Tooltip>
                            }
                          >
                            <div className="table-info width-120">
                              {childDevice.displayArea}
                            </div>
                          </OverlayTrigger>
                        </div>
                        <div className="table-column">
                          <div className="table-header">
                            {constants.DEVICES_LIST_SERIAL_NUMBER}
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{childDevice.serialNo}</Tooltip>}
                          >
                            <div className="table-info width-150">
                              {childDevice.serialNo}
                            </div>
                          </OverlayTrigger>
                        </div>
                        <div className="table-column">
                          <div className="table-header">
                            {constants.DEVICES_LIST_APPLICATION_LINKED}
                          </div>
                          <div className="table-info width-180">
                          {appList?.map((item, index) => (
                            <>
                              {Object.keys(device?.apps).includes(
                                item.id ===
                                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
                                  ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
                                  : item.id
                              ) && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>{item.name}</Tooltip>}
                                >
                                  <img
                                    alt=""
                                    // onClick={() => {
                                    //   appIconActionCheck(
                                    //     childDevice,
                                    //     item.id,
                                    //     index,
                                    //     childDevice?.deviceStatus
                                    //   );
                                    // }}
                                    className={`deviceIcon ${getClassName(
                                    childDevice.apps,
                                      item.id,
                                      childDevice?.deviceStatus
                                    )}`}
                                    src={item.gicon}
                                  />
                                </OverlayTrigger>
                              )}
                            </>
                          ))}
                          </div>
                        </div>
                        <div className="table-column">
                          <div className="table-header">
                            {constants.DEVICES_LIST_STATUS}
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {childDevice.displayDeviceStatus}
                              </Tooltip>
                            }
                          >
                            <div className="table-info width-120">
                              {getDeviceStatus(childDevice)}
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>

                  </div>
                 {/* start code for mobile responsive device */}
                    <div
                    key={childDevice.deviceId}
                    className="camera d-flex d-md-none"
                    // onClick={() => {
                    //   if (
                    //     device.deviceType.toUpperCase() !==
                    //     DeviceTypeEnum.DMSERVER
                    //   )
                    //     showDeviceDashboard(childDevice);
                    // }}
                  >

                    <div className="camera-details">
                    <Row className="gy-3">
                    <Col className="text-start" xs={12} md={12}>
                       <div className="device-name-mobile">{childDevice.deviceName}</div>
                    </Col>
                    <Col className="text-start" xs={10} md={10}>
                      <div className="label-title">{constants.DEVICES_LIST_STATUS_TITLE}</div>
                      <div>{getDeviceStatus(device)}</div>
                    </Col>
                    <Col className="text-start"  xs={6} md={6}>
                      <div className="label-title">{constants.DEVICES_LIST_LOCATION}</div>
                      <div> {device.displayLocation}</div>
                    </Col>
                    <Col  xs={6} md={6}>
                      <div className="label-title">{constants.DEVICES_LIST_AREA}</div>
                      <div>{device.displayArea}</div>
                    </Col>

                    <Col className="text-start" xs={6} md={6}>
                      <div className="label-title">{constants.DEVICE_LIST_MANUFACTURE}</div>
                      <div>{device.manufacturer}</div>
                    </Col>
                    <Col xs={6} md={6}>
                      <div className="label-title">{constants.DEVICES_MODEL_TEXT}</div>
                      <div>{device.modelNumber}</div>
                    </Col>
                    <Col className="text-start" xs={12} md={12}>
                      <div className="label-title">{constants.DEVICES_LIST_APPLICATION_LINKED}</div>
                      <div className="d-flex width-150 col-4 applicationAppMobile">
                          {appList?.map((item, index) => (
                            <>
                              {Object.keys(device?.apps).includes(
                                item.id ===
                                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
                                  ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
                                  : item.id
                              ) && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>{item.name}</Tooltip>}
                                >
                                  <img
                                    alt=""
                                    // onClick={() => {
                                    //   appIconActionCheck(
                                    //     childDevice,
                                    //     item.id,
                                    //     index,
                                    //     childDevice?.deviceStatus
                                    //   );
                                    // }}
                                    className={`deviceIconMobile ${getClassName(
                                      childDevice.apps,
                                      item.id,
                                      childDevice?.deviceStatus
                                    )}`}
                                    src={item.gicon}
                                  />
                                </OverlayTrigger>
                              )}
                            </>
                          ))}
                        </div>
                    </Col>
                  </Row>
                    </div>

                  </div>
                   {/* end code for mobile responsive device */}
                  </>
                ))}

              </Accordion.Body>

            </Accordion.Item>
          ) : (
            <div ref={rowRef} className="accordion-item" key={index}>
              <div className="w-100">
                <div className="header desktop">
                  <div
                    className="header-section-1 accordion-parent-device-name"
                    style={{
                      wordBreak: "break-word",
                    }}
                  >
                    <div className="image-wrapper">
                          <img
                            src={device?.childDevices?.[0]?.imageURL || device?.imageURL}
                            onError={(e) => e.target.src = `${DefaultDevice}`}
                            alt=""
                            className="device-icon"
                          />
                      
                    </div>
                    <div className="device-name">{device?.deviceName}</div>
                    {device?.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS && device?.isFirmwareUpgradeAvailable && device?.deviceType?.toUpperCase() !== DeviceTypeEnum.DMSERVER ? (
                      <div className="firmware-update d-none d-md-flex">
                        <div className="update-text">
                          {constants.DEVICES_FIRMWARE_UPGRADE_AVAILABLE}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="header-section-2">
                    <RiSettings5Fill
                      size={24}
                      className={"setting-icon"}
                      onClick={() => {
                        showChannelDetails(device);
                      }}
                    />
                  </div>
                </div>
                {/* start code for mobile responsive device */}
                <div className="-flex d-md-none">
                {device?.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS && device?.isFirmwareUpgradeAvailable && device?.deviceType?.toUpperCase() !== DeviceTypeEnum.DMSERVER ? (
                      <div className="firmware-update">
                        <div className="update-text">
                          {constants.DEVICES_FIRMWARE_UPGRADE_AVAILABLE}
                        </div>
                      </div>
                    ) : null}
                </div>
                  {/* end code for mobile responsive device */}
            </div>

              <div className="w-100 desktop">
                <div className="camera pt-2 pb-2">
                  <LatestSnapshot
                    key={device.childDevices?.[0]?.deviceId || device.deviceId}
                    deviceElemId={`camera${index}`}
                    orgId={orgDetails?.orgId}
                    cdnInfo={cdnInfo}
                    locationAreaName={device.childDevices?.[0]?.locationAreaNames || device.locationAreaNames}
                    isHighlighted={false}
                    showZoomOptions={false}
                    conStatus={device.connectionStatus || "offline"}
                    hubId={device.childDevices?.[0]?.deviceId || device.deviceId}
                    defaultImage={device?.imageURL}
                    {...device.childDevices?.[0]}
                  />
                  <div className="camera-details">
                    <div className="detail-table">
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_LOCATION}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{device.childDevices?.[0]?.displayLocation || device.displayLocation}</Tooltip>}
                        >
                          <div className="table-info width-120">
                            {device.childDevices?.[0]?.displayLocation || device.displayLocation}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_AREA}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{device.childDevices?.[0]?.displayArea || device.displayArea}</Tooltip>}
                        >
                          <div className="table-info width-120">
                            {device.childDevices?.[0]?.displayArea || device.displayArea}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_SERIAL_NUMBER}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{device.childDevices?.[0]?.serialNo || device.serialNo}</Tooltip>}
                        >
                          <div className="table-info width-150">
                            {device.childDevices?.[0]?.serialNo || device.serialNo}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_APPLICATION_LINKED}
                        </div>
                        <div className="d-flex width-150 col-4">
                          {appList?.map((item, index) => (
                            <>
                              {Object.keys(device.childDevices?.[0]?.apps || device?.apps).includes(
                                item.id ===
                                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
                                  ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
                                  : item.id
                              ) && (
                                <OverlayTrigger
                                  placement="top"
                                  key={item.id}
                                  overlay={<Tooltip>{item.name}</Tooltip>}
                                >
                                  <img
                                    alt=""
                                    // onClick={() => {
                                    //   appIconActionCheck(
                                    //     device.childDevices?.[0] || device,
                                    //     item.id,
                                    //     index,
                                    //     device.childDevices?.[0]?.deviceStatus || device?.deviceStatus
                                    //   );
                                    // }}
                                    className={`deviceIcon ${getClassName(
                                      device.childDevices?.[0]?.apps || device.apps,
                                      item.id,
                                      device.childDevices?.[0]?.deviceStatus || device?.deviceStatus
                                    )}`}
                                    src={item.gicon}
                                  />
                                </OverlayTrigger>
                              )}
                            </>
                          ))}
                        </div>
                      </div>

                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_STATUS}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>{device.childDevices?.[0]?.displayDeviceStatus || device.displayDeviceStatus}</Tooltip>
                          }
                        >
                          <div className="table-info width-120">
                            {getDeviceStatus(device.childDevices?.[0] || device)}
                          </div>
                        </OverlayTrigger>
                      </div>
                      {/* <div className="table-column">
                        <div className="table-header">
                          <RiSettings5Fill
                            size={24}
                            className={`setting-icon ${
                              device.deviceStatus === "PENDING_CLAIM"
                                ? "disable-icon"
                                : ""
                            }`}
                            onClick={() => {
                              if (device.deviceStatus === "PENDING_CLAIM")
                                return;
                              showDeviceDashboard(
                                device.childDevices?.[0] || device
                              );
                            }}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 mobile">
                <div className="camera pt-3 pb-3  container-fluid">
                  <Row className="gy-3">
                    <Col className="text-start" xs={10} md={10}>
                      <div className="label-title">{constants.DEVICES_LIST_STATUS_TITLE}</div>
                      <div>{getDeviceStatus(device.childDevices?.[0] || device)}</div>
                   </Col>
                      <Col className="text-start status-color-mobile" xs={2} md={2}>
                    {/* <div className="table-column">
                        <div className="table-header">
                          <RiSettings5Fill
                            size={24}
                            className={`setting-icon ${
                              device.deviceStatus === "PENDING_CLAIM"
                                ? "disable-icon"
                                : ""
                            }`}
                            onClick={() => {
                              if (device.deviceStatus === "PENDING_CLAIM")
                                return;
                              showDeviceDashboard(
                                device.childDevices?.[0] || device
                              );
                            }}
                          />
                        </div>
                      </div> */}
                    </Col>
                   <Col className="text-start"  xs={6} md={6}>
                      <div className="label-title">{constants.DEVICES_LIST_LOCATION}</div>
                      <div> {device.childDevices?.[0]?.displayLocation || device.displayLocation}</div>
                    </Col>
                    <Col  xs={6} md={6}>
                      <div className="label-title">{constants.DEVICES_LIST_AREA}</div>
                      <div>{device.childDevices?.[0]?.displayArea || device.displayArea}</div>
                    </Col>

                    <Col className="text-start" xs={6} md={6}>
                      <div className="label-title">{constants.DEVICE_LIST_MANUFACTURE}</div>
                      <div>{device.childDevices?.[0]?.manufacturer || device.manufacturer}</div>
                    </Col>
                    <Col xs={6} md={6}>
                      <div className="label-title">{constants.DEVICES_MODEL_TEXT}</div>
                      <div>{device.childDevices?.[0]?.modelNumber || device.modelNumber}</div>
                    </Col>
                    <Col className="text-start" xs={12} md={12}>
                      <div className="label-title">{constants.DEVICES_LIST_APPLICATION_LINKED}</div>
                      <div className="d-flex width-150 col-4 applicationAppMobile">
                          {appList?.map((item, index) => (
                            <>
                              {Object.keys(device.childDevices?.[0]?.apps || device?.apps).includes(
                                item.id ===
                                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
                                  ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
                                  : item.id
                              ) && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>{item.name}</Tooltip>}
                                >
                                  <img
                                    alt=""
                                    // onClick={() => {
                                    //   appIconActionCheck(
                                    //     device.childDevices?.[0] || device,
                                    //     item.id,
                                    //     index,
                                    //     device.childDevices?.[0]?.deviceStatus || device?.deviceStatus
                                    //   );
                                    // }}
                                    className={`deviceIconMobile ${getClassName(
                                      device.childDevices?.[0]?.apps || device.apps,
                                      item.id,
                                      device.childDevices?.[0]?.deviceStatus || device?.deviceStatus
                                    )}`}
                                    src={item.gicon}
                                  />
                                </OverlayTrigger>
                              )}
                            </>
                          ))}
                        </div>
                    </Col>
                  </Row>

                  {/* <div className="camera-details">
                    <div className="detail-table">
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_LOCATION}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{device.displayLocation}</Tooltip>}
                        >
                          <div className="table-info width-120">
                            {device.displayLocation}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_AREA}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{device.displayArea}</Tooltip>}
                        >
                          <div className="table-info width-120">
                            {device.displayArea}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_SERIAL_NUMBER}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{device.serialNo}</Tooltip>}
                        >
                          <div className="table-info width-150">
                            {device.serialNo}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_APPLICATION_LINKED}
                        </div>
                        <div className="d-flex width-150 col-4">
                          {appList?.map((item, index) => (
                            <>
                              {Object.keys(device?.apps).includes(
                                item.id ===
                                  constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
                                  ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
                                  : item.id
                              ) && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>{item.name}</Tooltip>}
                                >
                                  <img
                                    alt=""
                                    onClick={() => {
                                      appIconActionCheck(
                                        device,
                                        item.id,
                                        index,
                                        device?.deviceStatus
                                      );
                                    }}
                                    className={`deviceIcon ${getClassName(
                                      device.apps,
                                      item.id,
                                      device?.deviceStatus
                                    )}`}
                                    src={item.gicon}
                                  />
                                </OverlayTrigger>
                              )}
                            </>
                          ))}
                        </div>
                      </div>

                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_STATUS}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>{device.displayDeviceStatus}</Tooltip>
                          }
                        >
                          <div className="table-info width-120">
                            {getDeviceStatus(device)}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          <RiSettings5Fill
                            size={24}
                            className={`setting-icon ${
                              device.deviceStatus === "PENDING_CLAIM"
                                ? "disable-icon"
                                : ""
                            }`}
                            onClick={() => {
                              if (device.deviceStatus === "PENDING_CLAIM")
                                return;
                              showDeviceDashboard(
                                device.childDevices?.[0] || device
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : isDeviceAvailable && (
        <div className="no-devices-container">
          <div className="image-wrapper">
            <HiOutlineVideoCamera size={32} />
          </div>
          <div>{constants.DEVICE_NOT_ADDED}</div>
        </div>
      );
    };

    const handleDeviceClaimModel = (value) => {
        setIsDirectToScannedPopup(false);
        setShowClaimDeviceModal(value);
    };
    // for mobile responsive device
    const handleDeviceClaimMobileModel = (value) => {
      setIsDirectToScannedMobilePopup(false);
      setShowClaimMobileDeviceModal(value);
  };


    return (
      <div className="App">
        <Header breadcrumbData={breadList} className="Desktop" />
        <div className="breadcrumbsResponsive mobile">
          <div className="text-start me-auto navbar-brand arrow">
            <img src={arrowLeft} alt=""></img>
            <spam
              className="backText"
              onClick={() => navigate(mobileBreadList[0]?.url)}
            >
              {constants.BREADCRUMB_BACK}
            </spam>
          </div>
          <Nav>
            <NavDropdown
              className="mobile"
              title={
                <div>
                  <img className="dots" src={dotshorizontal} alt=""></img>
                </div>
              }
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item href="#" onClick={mobileModalView}>
                <div>
                  {constants.CAMERAS_VIDEO_WALL_ADD_DEVICE_BUTTON_LABEL}
                </div>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </div>
        <div className="App">
          <PageWrapper className="mw-100">
            <div className="page-header mb-3">
              <Container className="container-top-offset mw-100 container" lg={8}>
                <Col md={6} lg={6} xl={6} xs={12}>
                  <BreadcrumbList
                    list={Utils.CheckBreadcrumbForUserRole(breadList)}
                  />
                </Col>
                <Row>
                  <Col
                    md={6}
                    lg={6}
                    xl={6}
                    xs={12}
                    className="page-title text-start"
                  >
                    {constants.DEVICES_PAGE_TITLE}
                  </Col>

                  {/* {Utils.checkIfMobileView(console.log('dddd')) ? (
                    <>
                      <Row className="mb-2">
                        <Col xs={6}>
                          <PrimaryButton
                            className={`device-btn-list${
                              !enableAttachAllLicenses && " d-none"
                            }`}
                            type="button"
                            width="100%"
                            backgroundColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--brand_white")}
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--brand_black")}
                            fontSize="0.875rem"
                            height="44px"
                          >
                            {constants.DEVICES_ATTACH_ALL_LICENSES}
                          </PrimaryButton>
                        </Col>

                        <Col xs={6}>
                          {customerOrgPolicies &&
                            customerOrgPolicies?.filter(
                              (item) => item.policyName === "claim_device"
                            ) && (
                              <PrimaryButton
                                className="device-btn-list"
                                type="button"
                                width="100%"
                                backgroundColor={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue("--brand_white")}
                                color={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue("--brand_black")}
                                fontSize="0.875rem"
                                height="44px"
                              >
                                {constants.DEVICES_CLAIM_ALL_DEVICES}
                              </PrimaryButton>
                            )}
                        </Col>
                      </Row>
                    </>
                  ) : ( */}
                    <>
                    <Col md={6} lg={6} xl={6} xs={12} className="end-align">
                      <PrimaryButton
                        className={`device-btn-list${!enableAttachAllLicenses && " d-none"
                          }`}
                        type="button"
                        width="150px"
                        backgroundColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--brand_white")}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--brand_black")}
                        fontSize="0.875rem"
                        height="44px"
                      >
                        {constants.DEVICES_ATTACH_ALL_LICENSES}
                      </PrimaryButton>
                      {getCustomerOrgPolicies()?.install_device &&
                        <AddDevice
                          reloadData={fetchData}
                          showClaimDeviceModal={showClaimDeviceModal}
                          setShowClaimDeviceModal={handleDeviceClaimModel}
                          isDirectToScannedPopup={isDirectToScannedPopup}
                          // start for mobile responsive Device
                          showClaimMobileDeviceModal={showClaimMobileDeviceModal}
                          setshowClaimMobileDeviceModal={handleDeviceClaimMobileModel}
                          isDirectToScannedMobilePopup={isDirectToScannedMobilePopup}
                          // end for mobile responsive Device
                          mobileView={mobileView}
                          handleMobilePopup={setMobileView}
                        />
                      }
                    </Col>
                    </>
                  {/* )} */}
                </Row>
              </Container>
            </div>
            {discovredDevciesCount ? (
              <div className="discovred-devices-container">
                <div className="d-flex">
                  <HiOutlineInformationCircle size={20} />
                  <div className="deiscovered-device-message">
                    {constants.DISCOVERED_DEVICES_MESSAGE}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="pending-count">
                    {discovredDevciesCount}{' '}
                    {constants.DISCOVERED_DEVICES_PENDING_COUNT}
                  </div>
                  <PrimaryButton
                    className="device-btn-list btn-primary"
                    type="button"
                    width="132px"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_white')}
                    fontSize="12px"
                    height="32px"
                    lineHeight="16px"
                    borderRadius="8px"
                    onClick={() => {
                      navigate(
                        `/devices/discovered-devices.html?orgId=${orgDetails?.orgId}`
                      );
                    }}
                  >
                    {constants.DISCOVERED_DEVICES_SETUP_BUTTON}
                  </PrimaryButton>
                </div>
              </div>
            ) : null}
            <div className="list-block">
              <Container className="h-100 mw-100">
                {showLoader ? (
                  <div className="no-data-available">
                    <div className='position-absolute l-48 t-52'>
                      <SiteSpinner height="50px" width="50px" />
                      <div className='mt-2 text-dark'>{constants.LOADING}</div>
                    </div>
                  </div>
                ) : (
                  customerOrgPolicies &&
                  customerOrgPolicies?.filter(
                    (item) => item.policyName === "view_device"
                  ) && (
                    <div className="list-wrapper mt-3 mt-md-5">
                      <div className="filter-wrapper">
                        <div className="search-container">
                          <IoIosSearch className="search-icon" />
                          <input
                            type={"text"}
                            placeholder={"Search Devices"}
                            className="search-input"
                            onChange={(e) => onChangeSearchText(e)}
                          />
                        </div>
                        <Dropdown
                          className="device-status-selector"
                          autoClose="outside"
                        >
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id="dropdown-basic"
                            className="device-status-selector-toggle"
                          >
                            <div className="device-status-selected-wrapper">
                              <span className="device-status-selected-name">
                                Filter By
                              </span>
                            </div>
                            <HiOutlineChevronDown size={16} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="device-status-selector-menu">
                            <Dropdown.Item
                              className="device-status-selector-item"
                              onClick={(e) => onClickFilter("selectAll")}
                            >
                              Select All
                            </Dropdown.Item>
                            {filterOptions.map((option) => (
                              <Dropdown.Item
                                key={option.value}
                                className="device-status-selector-item"
                                onClick={(e) => onClickFilter(option.value)}
                              >
                                <input
                                  type="checkbox"
                                  checked={filterList.includes(option.value)}
                                  className="checkbox"
                                />
                                {option.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {!isDeviceAvailable && filteredDeviceData?.length ? (
                        <div>
                          <Accordion className="accordian" alwaysOpen>
                            {
                              <VariableSizeList
                                useIsScrolling
                                height={window.innerHeight - 300}
                                itemCount={filteredDeviceData?.length}
                                itemData={filteredDeviceData}
                                itemSize={getSize}
                                className='react-window-list'
                                ref={deviceListRef}
                              >
                                {ShowOrgSingletoneDevices}
                              </VariableSizeList>
                            }
                          </Accordion>
                        </div>
                      ) : (
                        isDeviceAvailable ? (
                          <div className="no-devices-container">
                            <div className="image-wrapper">
                              <HiOutlineVideoCamera size={32} />
                            </div>
                            <div>{constants.DEVICE_NOT_ADDED}</div>
                          </div>
                        ) : null
                      )}
                    </div>
                  )
                )}
                {showListScrollLoader ? (
                  <div className='show-variable-list-loader'>
                    <SiteSpinner height="50px" width="50px"></SiteSpinner>
                  </div>
                ) : null}
              </Container>
            </div>
            <SiteModal
              modalTitle={
                constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_TITLE
              }
              showModal={showDeviceRemoveModal}
              hideModal={clearRemoveDeviceData}
              classes="device-settings-restart-required"
            >
              {userMsg && userMsg.length > 0 && (
                <div className="error-container">
                  <div className="info-icon-container">
                    <InfoCircleIcon />
                  </div>
                  <div className="error-body">
                    <div className="error-content">{userMsg}</div>
                    <div className="cross-icon-container">
                      <RxCross1 onClick={() => setUserMsg("")} />
                    </div>
                  </div>
                </div>
              )}
              <TextBlock
                className="mb-3"
                fontWeight="400"
                fontSize="1rem"
                lineHeight="24px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--greyscale_56")}
              >
                {
                  constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_CONTENT
                }
              </TextBlock>
              {
                <>
                  <div className="text-center">
                    <HiExclamationCircle
                      className="mb-4 icon-warning-color"
                      size={96}
                    />
                  </div>
                  <PrimaryButton
                    className="btn btn-primary-outline mb-4"
                    type="button"
                    width="100%"
                    borderWidth="1.5px"
                    hoverBorderWidth="1.5px"
                    hoverBorderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--primary_40")}
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--primary_40")}
                    hoverColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--brand_white")}
                    hoverBackgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--primary_40")}
                    backgroundColor="transparent"
                    height="56px"
                    fontSize="1.125rem"
                    lineHeight="24px"
                    onClick={clearRemoveDeviceData}
                  >
                    {
                      constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
                    }
                  </PrimaryButton>
                  <PrimaryButton
                    className="btn"
                    type="button"
                    width="100%"
                    height="56px"
                    fontSize="1.125rem"
                    lineHeight="24px"
                    backgroundColor="transparent"
                    hoverBackgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    borderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    hoverBorderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    borderWidth="1.5px"
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    hoverColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--brand_white")}
                    onClick={deleteDeviceCall}
                  >
                    {
                      constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_MODAL_BUTTON_TITLE
                    }
                  </PrimaryButton>
                </>
              }
            </SiteModal>

            <SiteModal
              modalTitle={`${constants.DEVICES_LIST_APPLICATION_ACTIVATE} ${selectedApp?.device?.deviceName}`}
              showModal={showActiveModal}
              hideModal={() => {
                setShowActiveModal(false);
              }}
              classes="device-settings-restart-required"
            >
              <TextBlock
                className="mb-3"
                fontWeight="400"
                fontSize="1rem"
                lineHeight="24px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--greyscale_56")}
              >
                <div className="contentTitle">
                  {constants.DEVICES_LIST_APPLICATION_ACTIVATE_SUB_TITLE}{" "}
                  {selectedApp?.appLinkId}{" "}
                  {constants.DEVICES_LIST_APPLICATION_ACTIVATE_SUB_TITLE_TWO}
                </div>
              </TextBlock>
              <PrimaryButton
                className="btn mb-4 btn-primary"
                type="button"
                width="100%"
                borderWidth="1.5px"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--brand_primary")}
                height="56px"
                fontSize="1rem"
                lineHeight="24px"
                onClick={() => {
                  addAppLink();
                }}
              >
                {constants.DEVICES_LIST_APPLICATION_ACTIVATE_YES}
              </PrimaryButton>
              <Row className="settings-block">
                <Col>
                  <PrimaryButton
                    className="btn btn-primary-outline"
                    type="button"
                    width="100%"
                    height="56px"
                    fontSize="1.125rem"
                    lineHeight="24px"
                    backgroundColor="transparent"
                    hoverBackgroundColor="transparent"
                    borderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    hoverBorderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    borderWidth="1.5px"
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    hoverColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    onClick={() => {
                      setShowActiveModal(false);
                    }}
                  >
                    {constants.DEVICES_LIST_APPLICATION_ACTIVATE_CANCEL}
                  </PrimaryButton>
                </Col>
              </Row>
            </SiteModal>
            <SiteModal
              modalTitle={`${constants.DEVICES_LIST_APPLICATION_DEACTIVE} ${selectedApp?.device?.deviceName}`}
              showModal={showDeActiveModal}
              hideModal={() => {
                setShowDeActiveModal(false);
              }}
              classes="device-settings-restart-required"
            >
              <TextBlock
                className="mb-3"
                fontWeight="400"
                fontSize="1rem"
                lineHeight="24px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--greyscale_56")}
              >
                <div className="contentTitle">
                  {constants.DEVICES_LIST_APPLICATION_DEACTIVATE_SUB_TITLE}{" "}
                  {selectedApp?.appLinkId}{" "}
                  {constants.DEVICES_LIST_APPLICATION_DEACTIVATE_SUB_TITLE_TWO}
                </div>
              </TextBlock>
              <PrimaryButton
                className="btn mb-4 btn-primary"
                type="button"
                width="100%"
                borderWidth="1.5px"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--brand_primary")}
                height="56px"
                fontSize="1rem"
                lineHeight="24px"
                onClick={() => {
                  removeAppLink();
                }}
              >
                {constants.DEVICES_LIST_APPLICATION_ACTIVATE_REMOVE}
              </PrimaryButton>
              <Row className="settings-block">
                <Col>
                   <PrimaryButton
                    className="btn btn-primary-outline"
                    type="button"
                    width="100%"
                    height="56px"
                    fontSize="1.125rem"
                    lineHeight="24px"
                    backgroundColor="transparent"
                    hoverBackgroundColor="transparent"
                    borderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    hoverBorderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    borderWidth="1.5px"
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    hoverColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--error_64")}
                    onClick={() => {
                      setShowDeActiveModal(false);
                    }}
                  >
                    {constants.DEVICES_LIST_APPLICATION_ACTIVATE_CANCEL}
                  </PrimaryButton>
                </Col>
              </Row>
            </SiteModal>
          </PageWrapper>
        </div>
      </div>
    );
};

export default DeviceList;
