import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';
import { NavDropdown } from 'react-bootstrap';
import { constants, Utils, roles, AppDefaults } from '../../helpers';
import { usePoliciesStore } from '../../store/policiesStore';
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useDispatch } from 'react-redux';
import { setOrgInfo } from '../../store/reducers/OrganizationsReducer';
import { setSelectedOrganization } from '../../store/AccountStoreIDB';

const OrgListTable = ({ columns, data, defaultSortByData = [] }) => {
  const dispatch = useDispatch();
  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies
  );
  const setCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.setCustomerOrgUsers
  );
  const navigate = useNavigate();

  const [openDropdownOrgId, setOpenDropdownOrgId] = useState(null);

  const handleDropdownToggle = (orgId) => {
    setOpenDropdownOrgId(openDropdownOrgId === orgId ? null : orgId);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: defaultSortByData,
        },
      },
      useSortBy
    );

  return (
    <div className="table-responsive">
      <table className="table table-borderless" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={`${
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'columnheader-asc'
                        : 'columnheader-desc'
                      : 'columnheader-asc'
                  }`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={() => {
                    column.toggleSortBy(!column.isSortedDesc, false);
                  }}
                >
                  {column.render('Header')}
                  <span>
                    {column.id !== 'orgId' ? column.isSortedDesc ? (
                        <VscChevronDown size={16} strokeWidth="1px" />
                      ) : (
                        <VscChevronUp size={16} strokeWidth="1px" />
                      ):null
                    }
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={async() => {
                  let primaryOrgData = row?.original;
                  await setSelectedOrganization(primaryOrgData);
                  dispatch(setOrgInfo({
                    orgId: primaryOrgData?.orgId,
                    orgName: primaryOrgData?.orgName,
                    shortName: primaryOrgData?.shortName,
                  }));
                  setCustomerOrgUsers([]);
                  let policies = row?.original?.policies;
                  let updatedPoliciesData = Utils.mapUserPolicies(
                    DefaultPolicies,
                    policies
                  );
                  setCustomerOrgPolicies(updatedPoliciesData)
                  if (row?.original?.role === roles.ROLE1PORTAL) {
                    navigate(
                      `/landing-page.html?orgId=${row.values.orgId}&orgName=${row.values.orgName}&fromPush=true`
                    );
                  } else {
                    navigate(
                      `/customers/dashboard.html?orgId=${row.values.orgId}`
                    );
                  }
                }}
              >
                {row.cells.map((cell, index) => (
                  <td {...cell.getCellProps()} key={index}>
                    {cell.render('Cell')}
                  </td>
                ))}
                <td>
                  <NavDropdown
                    className="table-action-dropdown"
                    title={'...'}
                    onClick={(event) => event.stopPropagation()}
                    onToggle={() => handleDropdownToggle(row.values.orgId)}
                    show={openDropdownOrgId === row.values.orgId}
                  >
                    <NavDropdown.Item
                      onClick={() => {
                        const policies = row?.original?.policies;
                        const updatedPoliciesData = Utils.mapUserPolicies(
                          DefaultPolicies,
                          policies
                        );
                        setCustomerOrgPolicies(updatedPoliciesData);
                        navigate(
                          `/customers/editOrganization.html?orgId=${row.values.orgId}`
                        );
                      }}
                    >
                      {constants.ORGANIZATION_EDIT}
                    </NavDropdown.Item>
                  </NavDropdown>
                </td>
              </tr>
            );
          })}
          {rows?.length === 0 && (
            <tr className="no-data-found">
              <td colSpan={columns?.length}>
                {constants.NO_MANAGE_ORG_CUSTOMER_FOUND_TITLE}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OrgListTable;
