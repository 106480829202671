import React, { useEffect, useState, useCallback } from 'react';
import { Col, Form, Row, Container, Alert } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  RangeInputField,
  SelectField,
  TextField,
  ToggleInput,
  AssignLocations
} from '../../components/forms';
import { constants, Utils, roles, AppDefaults } from '../../helpers';
import Autocomplete from 'react-google-autocomplete';
import { BiWorld } from 'react-icons/bi';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { usePoliciesStore } from '../../store/policiesStore';
import axios from 'axios';
import timezones from '../../data/support/timezone.json';
import { PiWarningCircleBold } from 'react-icons/pi';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import LdapAuthentication from './LdapAuthentication';
import { useSearchParams } from 'react-router-dom';
// import {
//   setSelectedOrganization,
// } from '../../store/reducers/AccountReducer';
import { useDispatch } from 'react-redux';
import { findTimeZoneFromSelectedValue, moveArrayPosition } from '../../helpers/commonUtils';
import {
  Header,
  PrimaryButton,
  TextBlock,
  SiteModal,
  SiteSpinner,
  BreadcrumbList,
  Footer
} from '../../components/common/';
import LdapSettings from './LdapSettings';
import './LdapAuthentication.scss';
import RemoveOrganization from './RemoveOrganization';
import { IoClose } from "react-icons/io5";
import LocationSelectField from '../../components/forms/LocationSelectField';
import {
  getCustomerOrgData,
  getOrganizations,
} from '../../store/OrganizationsStoreIDB';
import { setSelectedOrganization } from '../../store/AccountStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

// Schema for yup
const validationSchema = Yup.object().shape({
  orgName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .required(constants.CUSTOMER_NAME_REQUIRED_ERROR_MESSAGE),
  addressLine1: Yup.string().required(constants.ADDRESS_REQUIRED_ERROR_MESSAGE),
  orgWebsite: Yup.string()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
      constants.WEBSITE_CORRECT_URL_MESSAGE
    ),
  orgLinkedInProfile: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
    constants.WEBSITE_CORRECT_URL_MESSAGE
  ),
  timezone: Yup.string().required(constants.TIMEZONE_REQUIRED_ERROR_MESSAGE)
});

function EditOrganization() {
  const zoneTimeList = timezones?.data;
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const dispatch = useDispatch();
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [locHelperText, setLocHelperText] = useState('');
  const [visibleLocHelperText, setVisibleLocHelperText] = useState(false);
  const [disableRemoveOrg, setDisableRemoveOrg] = useState(false);
  const [ldapEnabled, setLdapEnabled] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [orgContact, setOrgContact] = useState([]);
  const [authenticationSucess, setAuthenticationSucess] = useState(false);
  const [showLdapSettings, setShowLdapSettings] = useState(false);
  const [showRemoveOrgModal, setShowRemoveOrgModal] = useState(false);
  const [custPartnerOrgData, setCustPartnerOrgData] = useState({});
  const [isChangeLdap, setIsChangeLdap] = useState(false);
  //=== Store get/set actions
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const [currentUserTimezone, setCurrentUserTimezone] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  const [orgDetails, setOrgDetails] = useState();

  const getOrganizationContact = useOrganizations(
    (state) => state.getOrganizationContact
  );
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  const loggedInUser = getLoggedInUserData();
  // const getOrganizations = useOrganizations((state) => state.getOrganizations);
  
  const breadList = [
    {
      url: '/customers/manage.html',
      title: (loggedInUser?.orgType !== AppDefaults?.ORG_TYPE_CUSTOMER) ? constants.MANAGE_ORG_PAGE_TITLE : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}&orgName=${editUser?.orgName}&fromPush=true`,
      title: editUser?.orgName,
      disable: getLoggedInUserPolicies()?.view_cust_org_dashboard ? false : true
    },
    {
      url: `/customers/editOrganization.html?orgId=${orgId}`,
      title: 'Edit Organization',
    },
  ];

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs || []);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  const fetchOrganizationData = async () => {
    setShowSpinner(true);
    try {
      await getOrganizations(`/partner/account/self`);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      setUserMsg(error);
    }
  };

  const disableInput = (event, values) => {
    if (values.addressLine1) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  useEffect(() => {
    const init = async () => {
      if (Array.isArray(orgDetails)) {
        const userIndex = orgDetails?.find(
          (user) => user.orgId === orgId
        );
        await setSelectedOrganization(userIndex);
        setEditUser({
          ...userIndex,
        });
        setLdapEnabled(userIndex.ldapEnabled)
        if(userIndex.ldapEnabled){
          setAuthenticationSucess(true);
        }
      }
      axios
      .get(`partner/orgs/${orgId}/partnerOrg`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setCustPartnerOrgData(responseData);
          } else {
            setCustPartnerOrgData({});
          }
        }
      });
    }
    init();
  }, [orgDetails?.length]);

  useEffect(() => {
    // get data for organization contact dropdown
    const roleParam = encodeURIComponent(roles.ROLE2PORTAL);
    getOrganizationContact(`/partner/orgs/${orgId}/accounts/v2?roleIds=${roleParam}`)
    .then((res) => {
      let updatedContacts = res ?? [];
      if (editUser?.contactId) {
        const selectedObjIndex = res?.findIndex(
          (data) => data?.value === editUser?.contactId
        );
        updatedContacts = moveArrayPosition(res, selectedObjIndex, 0);
      }
      setOrgContact(updatedContacts);
    })
    .catch((err) => {
      console.log(err);
    });
    const timezone = findTimeZoneFromSelectedValue(editUser?.timezone);
    setCurrentUserTimezone(timezone);

  }, [editUser]);

  useEffect(() => {
    const activeDevices = editUser?.devices?.filter(
      (device) =>
        device.deviceStatus !== constants.DEVICES_DEACTIVATED_DEVICE_STATUS
    );
    const activeUsers = getCustomerOrgUsersData()?.filter(
      (user) =>
        (user.role === roles.ROLE2PORTAL || user.role === roles.ROLE3PORTAL
          || user.role === roles.ROLE7PORTAL) &&
        loggedInUser?.accountId !== user?.accountId
    );
    if(activeUsers?.length == 0 && activeDevices?.length == 0){
      setDisableRemoveOrg(false);
    }else{
      setDisableRemoveOrg(true);
    }
  }, [editUser]);
  const onLdapChange = ( ldapValue) => {
    setIsChangeLdap(ldapValue);
    setLdapEnabled(ldapValue);
    if(!ldapValue){
      setAuthenticationSucess(false);
    }
  }

  const onCloseLdap = (status) => {
    setIsChangeLdap(status);
    if(status){
      setLdapEnabled(true);
      setAuthenticationSucess(true);
    } else {
      setLdapEnabled(false);
      setAuthenticationSucess(false);
    }
  }

  const handleFailure = (e)=>{
    if(e?.meta?.code === 1109 || e?.meta?.code === 1108){
      if(userMsg === e?.meta?.userMsg){
        setUserMsg(e?.meta?.userMsg + ' ');
        setVariant('danger');
      }else{
        setUserMsg(e?.meta?.userMsg);
        setVariant('danger');
      }
    }
    setShowRemoveOrgModal(false)
  }
  return (
    <div className="org-detail-page">
    <Header breadcrumbData={breadList} />
    <Container className='mw-100'>
      <Row className="justify-content-md-left">
        <div className="page-header">
          <Container className='mw-100'>
            <Row>
              <Col className="text-start">
                <BreadcrumbList
                  isFromOrgDetails={false}
                  list={breadList}
                />
                <Alert
                  variant={variant}
                  show={!!userMsg}
                  onClose={() => setUserMsg('')}
                  dismissible
                >
                  {userMsg}
                </Alert>
              </Col>
            </Row>
            <section className="modal-main-content mt-3">
              {
                editUser.orgId && currentUserTimezone !== undefined ?
                <Formik
                  initialValues={{
                    //orgId: editUser?.orgId,
                    orgName: editUser?.orgName,
                    displayDetails: `${editUser.addressLine1}${editUser.city}, ${editUser.stateName}, ${editUser.country}, ${editUser?.zipcode}`,
                    addressLine1: editUser?.addressLine1,
                    addressLine2: editUser?.addressLine2,
                    city: editUser?.city,
                    state: editUser?.stateName,
                    country: editUser?.country,
                    zipcode: editUser?.zipcode,
                    orgWebsite: editUser?.orgWebsite?.toLowerCase(),
                    orgLinkedInProfile: editUser?.orgLinkedInProfile,
                    //  createdDate: editUser?.createdDate,
                    orgStatus: editUser?.orgStatus,
                    lat:
                      editUser?.locations?.find(
                        (location) => location.isDefault === 'true'
                      )?.lat ?? '',
                    lng:
                      editUser?.locations?.find(
                        (location) => location.isDefault === 'true'
                      )?.lng ?? '',
                    timezone: currentUserTimezone?.location,
                    mfa: editUser?.mfa,
                    contactId: editUser?.contactId ?? '',
                  }}
                  enableReinitialize={false}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    setUserMsg('');
                    if (values) {
                      axios
                        .put(
                          `partner/orgs/${editUser?.orgId}`,
                          values,
                          Utils.requestHeader()
                        )
                        .then((res) => {
                          let response = res.data;

                          if (response?.meta?.code === 200) {
                            // TODO : delete later
                            // dispatch(setuserData(response.data));
                            fetchOrganizationData();
                            setEditUser(response.data);
                            setSubmitting(false);
                            setUserMsg(
                              constants.ORGANIZATION_SETTINGS_PROFILE_SETTINGS_SUCCESS_TEXT
                            );
                            setVariant('success')
                            resetForm({ values });
                          } else {
                            setVariant('danger');
                            setUserMsg(response?.meta?.userMsg);
                            setSubmitting(false);
                          }
                        })
                        .catch(function (error) {
                          setVariant('danger');
                          setUserMsg(error?.message);
                          setSubmitting(false);
                        });
                    } else setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                  }) => (
                    <>
                      <Form className="modal-form" onSubmit={handleSubmit}>

                        <div className='head-container'>
                          <div className="header-title">
                            {constants.ORGANIZATION_EDIT}
                          </div>
                          <div className='d-flex gap-3'>
                            {getCustomerOrgPolicies()?.remove_cust_org ? (
                              <>
                                <Form.Group as={Row}>
                                  <Col>
                                    <PrimaryButton
                                      className="btn btn-outline-danger"
                                      type="button"
                                      height="44px"
                                      width="100%"
                                      fontSize="0.875rem"
                                      backgroundColor={getComputedStyle(
                                        document.documentElement
                                      ).getPropertyValue('--brand_white')}
                                      borderColor={getComputedStyle(
                                        document.documentElement
                                      ).getPropertyValue('--error_64')}
                                      color={getComputedStyle(
                                        document.documentElement
                                      ).getPropertyValue('--error_64')}
                                      borderWidth="1.5px"
                                      hoverBorderWidth="1.5px"
                                      hoverColor={getComputedStyle(
                                        document.documentElement
                                      ).getPropertyValue('--error_64')}
                                      hoverBackgroundColor={getComputedStyle(
                                        document.documentElement
                                      ).getPropertyValue('--brand_white')}
                                      disabled={disableRemoveOrg}
                                      hoverBorderColor={getComputedStyle(
                                        document.documentElement
                                      ).getPropertyValue('--error_64')}
                                      onClick={() => {
                                        setShowRemoveOrgModal(true);
                                      }}
                                    >
                                      {constants.REMOVE_ORGANIZATION}
                                    </PrimaryButton>
                                  </Col>
                                </Form.Group>
                              </>
                            ) : null}
                              <Form.Group className="d-none d-md-flex">
                                {
                                ((getLoggedInUserData()?.role !== roles.ROLE5PORTAL && getLoggedInUserData()?.orgType === 'INSTALLER_ORG')
                                 ? getLoggedInUserPolicies()
                                     .update_partner_org
                                 : getLoggedInUserPolicies().update_cust_org) && <PrimaryButton
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={
                                      isSubmitting ||
                                      (!(dirty && isValid) &&
                                        getCustomerOrgPolicies()
                                          ?.update_cust_org) ||
                                      currentUserTimezone == undefined ||
                                      !values.timezone ||
                                      !values.city ||
                                      !values.state ||
                                      !values.zipcode ||
                                      !values.country
                                    }
                                    loader={isSubmitting}
                                    height="44px"
                                    width="96px"
                                    fontSize="0.875rem"
                                  >
                                    {constants.EDIT_CUSTOMER_ORG}
                                  </PrimaryButton>
                                }
                              </Form.Group>
                          </div>
                        </div>
                        <TextBlock
                          fontWeight="400"
                          fontSize="1rem"
                          lineHeight="1.5rem"
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--greyscale_56')}
                          className="mb-3"
                        >
                          {`Organization ID: ${editUser?.orgId}`}
                        </TextBlock>
                        <Col xs lg="6">
                          <TextField
                            required={true}
                            removebottommargin="true"
                            removetopmargin="true"
                            label=""
                            placeholder={constants.CUSTOMER_NAME_FIELD}
                            name="orgName"
                            type="text"
                            disabled={(getLoggedInUserData()?.role !== roles.ROLE5PORTAL && getLoggedInUserData()?.orgType === 'INSTALLER_ORG')
                              ? getLoggedInUserPolicies()
                                  ?.update_partner_org ? false :true
                              : getLoggedInUserPolicies()?.update_cust_org ?false : true}
                          />
                             <TextField
                                  label=""
                                  name="shortName"
                                  value={editUser?.shortName}
                                  type="text"
                                  disabled={true}
                                />

                          <div className="row">
                            <div className="col position-relative address-with-reset">
                              <div>
                                <Autocomplete
                                      name="displayDetails"
                                      placeholder=""
                                      required={true}
                                      apiKey={process.env.REACT_APP_GOOGLE_PLACE_URL}
                                      onChange={(evt) => {
                                        setFieldValue('displayDetails', evt?.target?.value);
                                      }}     
                                      onKeyDown={(e) => disableInput(e, values)}                                   
                                      onBlur={handleBlur}
                                      id="displayDetails"
                                      // onFocus={
                                      //   (e) => e.target.setAttribute("autoComplete", "none")
                                      // }
                                      options={{
                                        fields: [
                                          'formatted_address',
                                          'geometry',
                                          'name',
                                          'address_components',
                                        ],
                                        strictBounds: false,
                                        types: ['address'],
                                      }}
                                      onPlaceSelected={(place) => {
                                        if(place?.address_components?.length){
                                        setFieldValue('lat', place?.geometry?.location?.lat());
                                        setFieldValue('lng', place?.geometry?.location?.lng());

                                        let addressComponent, addressComponentType, rawzipcode, rawcountry, rawcity, rawstate, rawaddline;
                                        for (
                                          let i = 0;
                                          i < place?.address_components?.length;
                                          i++
                                        ) {
                                          addressComponent = place.address_components[i];
                                          let j = 0;
                                          addressComponentType = addressComponent.types[j];
                                          if (addressComponentType === 'postal_code') {
                                            rawzipcode = addressComponent.long_name ? addressComponent.long_name : '';
                                          }
                                          if (addressComponentType === 'country') {
                                            rawcountry = addressComponent.long_name ? addressComponent.short_name : '';
                                          }
                                          if (addressComponentType === 'administrative_area_level_1') {
                                            rawstate = addressComponent.long_name  ? addressComponent.short_name : '';
                                          }
                                          if (addressComponentType === 'locality') {
                                            rawcity = addressComponent.long_name  ? addressComponent.long_name : '';
                                          }
                                          
                                          if (
                                            addressComponentType === 'street_number' || 
                                            addressComponentType === 'route' || 
                                            addressComponentType === 'neighborhood' || 
                                            addressComponentType.includes('sublocality')
                                          ){
                                            rawaddline = `${rawaddline ? rawaddline : ''}${addressComponent.long_name}, `;
                                          }
                                        }
                                        setFieldValue('zipcode', rawzipcode);
                                        setFieldValue('country', rawcountry);
                                        setFieldValue('city', rawcity);
                                        setFieldValue('state', rawstate);
                                        setFieldValue('addressLine1', rawaddline);
                                        setFieldValue('displayDetails', 
                                        (rawaddline ? rawaddline : '') + 
                                        (rawcity ? rawcity + ', ' : '') + 
                                        (rawstate ? rawstate + ', ' : '') + 
                                        (rawcountry ? rawcountry + ', ' : '') + 
                                        (rawzipcode ? rawzipcode : ''));

                                        if(place?.geometry?.location?.lat() && place?.geometry?.location?.lng()){

                                          var config = {
                                            method: 'get',
                                            url: `https://maps.googleapis.com/maps/api/timezone/json?location=${place?.geometry?.location?.lat()},${place?.geometry?.location?.lng()}&timestamp=${
                                              new Date().getTime() / 1000
                                            }&language=es&key=${
                                              process.env.REACT_APP_GOOGLE_TIMEZONE_API_KEY
                                            }`,
                                            headers: {},
                                          };

                                          fetch(config?.url)
                                          .then(function (response) {
                                            return response.json();
                                          })
                                          .then(function (json) {
                                            if (json?.status === 'OK') {
                                              const data = findTimeZoneFromSelectedValue(json?.timeZoneId);
                                              if (data) {
                                                setCurrentUserTimezone(data);
                                                setFieldValue('timezone', data?.location);
                                                setLocHelperText(
                                                  constants.LOCATION_TIMEZONE_ORGANIZATION_MESSAGE
                                                );
                                              } else {
                                                setLocHelperText(
                                                  constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                                );
                                                setFieldValue('timezone', '');
                                              }
                                              setVisibleLocHelperText(true);
                                            } else {
                                              setVisibleLocHelperText(true);
                                              setLocHelperText(
                                                constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                              );
                                            }
                                          });
                                        }
                                        }
                                      }}
                                      value={values.displayDetails}
                                      className={`form-control shadow-none autoaddress ${
                                        touched.displayDetails &&
                                        (errors.addressLine1 || !values.displayDetails) &&
                                        'is-invalid'
                                      } ${values.addressLine1 && 'has-value'}`}
                                        disabled={(getLoggedInUserData()?.role !== roles.ROLE5PORTAL && getLoggedInUserData()?.orgType === 'INSTALLER_ORG')
                              ? getLoggedInUserPolicies()
                                  ?.update_partner_org ? false :true
                              : getLoggedInUserPolicies()?.update_cust_org ?false : true}
                                    />
                                {!document.getElementById("displayDetails")?.value ?
                                  <span className="placeholder-wrapper required">
                                    {constants.ADDRESS_FIELD}
                                  </span>: null 
                                }
                                {values.addressLine1 ? 
                                        <span className={`reset-address-wrapper`}>
                                          <IoClose size={15}
                                            onClick={()=> {  
                                            setFieldValue('zipcode', '');
                                            setFieldValue('country', '');
                                            setFieldValue('city', '');
                                            setFieldValue('state', '');
                                            setFieldValue('addressLine1', '');   
                                            setFieldValue('displayDetails', ''); 
                                            setFieldValue('timezone', '');
                                            document.getElementById("displayDetails").value='';
                                            setFieldTouched('displayDetails', true, false);
                                          }}/>
                                        </span> : null 
                                      }
                              </div>
                              <ErrorMessage
                                component="div"
                                name="addressLine1"
                                className="error"
                              />
                              {touched.displayDetails &&
                                  (!values.addressLine1 ||
                                  !values.city || !values.state || !values.country || !values.zipcode) && (
                                  <div className="error">
                                    {constants.ADDRESS_FIELD_ERROR_MESSAGE}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col position-relative">
                              <div>
                                <LocationSelectField
                                  isFromOrg={true}
                                  name="timezone"
                                  options={zoneTimeList}
                                  label=""
                                  defaultOptionLabel="Time Zone"
                                  value={values.timezone}
                                  visibleLocHelperText={visibleLocHelperText}
                                  locHelperText={locHelperText}
                                  required={true}
                                  labelclasses='org-modal-Timezone'
                                  onChange={(event) => {
                                    let selectedVal = event?.target?.value;
                                    const getLocationSelected = findTimeZoneFromSelectedValue(selectedVal);
                                    setCurrentUserTimezone(getLocationSelected ?? '');
                                    setFieldValue('timezone', getLocationSelected?.location);
                                    setVisibleLocHelperText(false);
                                  }}
                                    disabled={(getLoggedInUserData()?.role !== roles.ROLE5PORTAL && getLoggedInUserData()?.orgType === 'INSTALLER_ORG')
                              ? getLoggedInUserPolicies()
                                  ?.update_partner_org ? false :true
                              : getLoggedInUserPolicies()?.update_cust_org ?false : true}
                                />
                              </div>

                              {visibleLocHelperText ? (
                                <div className="info-timezone">
                                  <PiWarningCircleBold
                                    color={getComputedStyle(
                                      document.documentElement
                                    ).getPropertyValue('--greyscale_56')}
                                    size={16}
                                  />
                                  <div className="message-info">{locHelperText}</div>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <TextField
                            beforeinputicon={<BiWorld size={12} />}
                            removebottommargin="true"
                            removetopmargin="true"
                            label=""
                            placeholder={constants.WEBSITE_NAME_FIELD}
                            name="orgWebsite"
                            type="text"
                              disabled={(getLoggedInUserData()?.role !== roles.ROLE5PORTAL && getLoggedInUserData()?.orgType === 'INSTALLER_ORG')
                              ? getLoggedInUserPolicies()
                                  ?.update_partner_org ? false :true
                              : getLoggedInUserPolicies()?.update_cust_org ?false : true}
                          />

                          <TextField
                            beforeinputicon={<AiOutlineLinkedin size={12} />}
                            removebottommargin="true"
                            removetopmargin="true"
                            label=""
                            placeholder={constants.LINKEDIN_PROFILE_NAME_FIELD}
                            name="orgLinkedInProfile"
                            type="text"
                              disabled={(getLoggedInUserData()?.role !== roles.ROLE5PORTAL && getLoggedInUserData()?.orgType === 'INSTALLER_ORG')
                              ? getLoggedInUserPolicies()
                                  ?.update_partner_org ? false :true
                              : getLoggedInUserPolicies()?.update_cust_org ?false : true}
                          />
                          
                          {getCustomerOrgPolicies()?.update_cust_org_settings ? (
                                <ToggleInput
                                  name="mfa"
                                  label={constants.ORGANIZATION_TWO_FACTOR_AUTHENTICATION}
                                  value={values.mfa}
                                  changeHandler={() => setFieldValue('mfa', !values.mfa)}
                                />
                              ) : null}

                          {getCustomerOrgPolicies()?.manage_ldap  ?
                            <div className='grey-background'>
                              <ToggleInput
                                name="ldapenabled"
                                label={constants.ORGANIZATION_LDAP_AUTHENTICATION}
                                value={ldapEnabled}
                                changeHandler={() => onLdapChange(!values.ldap)}
                                classes={`mt-3 ${authenticationSucess ? 'ldapEnabledClass' : ''}`}
                                disabled={authenticationSucess}
                              />

                              {authenticationSucess ?
                                <AssignLocations
                                label={'Settings'}
                                hideIcon={true}
                                classes={'mt-0'}
                                clickHandler={(evebr) => {
                                  setShowLdapSettings(true)
                                }}
                              />
                                : null
                              }
                            </div>
                            : null
                          }

                          <SelectField
                            name="contactId"
                            key="contactId"
                            options={orgContact}
                            defaultOptionLabel={
                              constants.ORGANIZATION_CONTACT_DROPDOWN_LABEL
                            }
                              disabled={(getLoggedInUserData()?.role !== roles.ROLE5PORTAL && getLoggedInUserData()?.orgType === 'INSTALLER_ORG')
                              ? getLoggedInUserPolicies()
                                  ?.update_partner_org ? false :true
                              : getLoggedInUserPolicies()?.update_cust_org ?false : true}
                          />

                          
                          {disableRemoveOrg ? (
                            <div className="d-flex mt-3 gap-2">
                              <div>
                                <PiWarningCircleBold
                                  color={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--greyscale_56')}
                                  size={20}
                                />
                              </div>
                              <TextBlock
                                fontWeight="400"
                                fontSize="0.875rem"
                                lineHeight="20px"
                                color={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--greyscale_56')}
                              >
                                {constants.REMOVE_ORG_HELPER_TEXT}
                              </TextBlock>
                            </div>
                          ) : null}
                          <div className="d-flex mt-3 gap-2">
                           <Form.Group className='d-flex d-md-none'>
                              <PrimaryButton
                                className="btn btn-primary"
                                type="submit"
                                disabled={
                                  (!(dirty && isValid) &&
                                    getCustomerOrgPolicies()?.update_cust_org) ||
                                  currentUserTimezone == undefined ||
                                  !values.city ||
                                  !values.state ||
                                  !values.zipcode ||
                                  !values.country
                                }
                                loader={isSubmitting}
                                height="44px"
                                width="96px"
                                fontSize="0.875rem"
                              >
                                {constants.EDIT_CUSTOMER_ORG}
                              </PrimaryButton>
                            </Form.Group>
                            </div>
                        </Col>
                      </Form>
                    </>
                  )}
                </Formik> :
                <div className='w-100 text-center'>
                  <SiteSpinner height="100px" width="100px"></SiteSpinner>
                </div>
              }
              <LdapAuthentication isChangeLdap={isChangeLdap} onCloseLdap={onCloseLdap} orgId={orgId}/>
              <SiteModal
                modalHeader={true}
                showModal={showLdapSettings}
                hideModal={() => setShowLdapSettings(false)}
              >
                <LdapSettings handleClose={() => setShowLdapSettings(false)} orgId={orgId}/>
              </SiteModal>
              <SiteModal
                modalTitle={`Remove ${editUser.orgName}`}
                showModal={showRemoveOrgModal}
                hideModal={() => setShowRemoveOrgModal(false)}
                classes="remove-org-modal"
              >
                <RemoveOrganization
                  editUser={editUser}
                  timezone={custPartnerOrgData?.timezone}
                  hideRemoveOrgModal={() => setShowRemoveOrgModal(false)}
                  handleAPIFailure ={(e)=>{handleFailure(e)}}
                />
              </SiteModal> 
            </section>
            <section className="modal-main-content mt-3">
              { !showSpinner ? <Footer 
              alignLeft="true" />: null}
            </section>
          </Container>
        </div>

      </Row>
    </Container>
  </div>
  );
}

export default EditOrganization;
